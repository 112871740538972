<template>
    <v-ons-bottom-toolbar :class="{ iPhoneFooter:terminal === 'iPhone', AndroidFooter:terminal === 'Android' }"></v-ons-bottom-toolbar>
</template>

<script>
    export default {
        name: "BottomToolbar",
        data(){
            return{
                terminal: this.$_Terminal()
            }
        },
        mounted() {
            this.terminal = this.$_Terminal()
        }
    }
</script>

<style scoped>
    .iPhoneFooter {
        background-color: black;
        border-top: solid 1px #b0afaf;
        height: 44px;
    }
    .AndroidFooter{
        background-color: black;
        border-top: solid 1px #b0afaf;
        height: 60px;
    }
</style>