<!--
  Title        : スポンサー一覧
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <div class="sponsor">
            <div class="fixed" v-for="(sponsor,index) in res" :key="index">
                <img id="sponsor" :src="sponsor.sponsor_img" @click="push(sponsor.sponsor_id)" />
                <div id="company_name" v-if="sponsor.sponsor_flg != 3">
                    {{ festival_name }}<br>
                    {{ sponsor.company_name }}
                </div>
                <div id="company_name" v-if="sponsor.sponsor_flg == 3">
                    {{ sponsor.company_name }}
                </div>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
    export default {
        name: "SponsorList",
        data(){
            return{
                townData: new Object(),
                res: [],
                alertLoadingVisible: false,
                alertErrorVisible: false,
                modalVisible: false,
                festival_name: this.$_FestivalData().festival_name,
            }
        },
        mounted: function() {
            let self = this;
            this.townData = this.$_TownData();
            this.townData.Sponsors.forEach(function(val){
                self.res.push(val)
            })
        },
        methods: {
            push(Val){
                this.$_setPage('SponsorList')
                return(
                    this.$_setSponsor_id(Val),
                    //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                    this.$_SetmixPC_CurrentPage_Key('sponsorDetailTab')
                );
            },
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .sponsor{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2%;
        color: white;
        background: #fffbea;
        border-radius: 20px;
        width: 90%;
        margin: 5% 2%;
        padding: 2% 3% 3%;
    }
    .fixed{
        display: flex; /* 追加 */
        flex-direction: column; /* 追加 */
        align-items: center; /* 追加 */
        text-align: center;
        position: relative;
        margin: 10% 0;
    }
    #sponsor{
        vertical-align:top;
        border: solid 1px black;
        width: 100%;
        height: 100px;
        margin: 0;
    }
    #company_name{
        font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        word-wrap:break-word;
        white-space:pre-wrap;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: url("/img/parts/金色屏風-背景.jpg");
        color: black;
        border: solid 1px black;
        font-size: 16px;
        width: 100%;
        height: auto;
        margin: 0;
    }
</style>