<!--
  Title        : 商品選択
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div class="info">
            <span style="color: red;font-size: 22px">※受注の締め切りに関して</span><br><br>
            いつもご愛顧いただき、誠にありがとうございます。<br>
            現在、大変多くのご注文をいただいておりますが、縫製工場の受注数の上限に達してしまいました。<br>
            そのため、これ以上の新しいご注文については、希望納期内に出荷が難しい状況となっております。<br>
            お客様には大変ご迷惑をおかけいたしますが、一時的に受注を締め切らせていただきます。<br><br>
            再開時期に関しましては、改めてアプリ内でお知らせをさせていただきます。<br>
            なお、商品の生地、柄、金額等は引き続きご確認いただけますので何卒、ご理解とご協力のほどよろしくお願い申し上げます。
        </div>

        <div style="color: white;text-align: left">

            <h2 class="title">商品選択</h2>
            <div class="title">ご希望の商品を選択してください。</div>

            <div class="goods_list">
                <div v-for="(item, index) in goods" :key="index" style="margin-bottom: 5%">
                    <div class="content_background">
                        <div class="content" @click="goodsSelection(item.name)">
                            <img class="item_img" :src="item.img">
                            <span class="icon"></span>
                            <div class="content_name">
                                <h2 class="item_name">{{ item.name }}</h2>
                                <div class="item_detail">{{ item.detail }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overlay" v-show="alert">
            <div class="description_content" slot="title">
                <carousel
                        :per-page="1"
                        :pagination-enabled="true"
                        :pagination-size="8"
                        :pagination-padding="5"
                        :autoplay="false"
                        :loop="false"
                        :autoplayHoverPause="true">
<!--                    <slide>-->
<!--                        <div class="slide">-->
<!--                            <img class="description_img" src="img/parts/top.png">-->
<!--                            <div class="description">-->
<!--                                オリジナルのだんじりグッズを<br>-->
<!--                                簡単・低価格・高品質で制作します。<br>-->
<!--                                こだわりのだんじりグッズで、家族や友人たちとだんじりを楽しみましょう!-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </slide>-->

                    <slide>
                        <div class="slide">
                            <img class="description_img" src="img/parts/STEP-1.png">
                            <div class="step">
                                <div id="step">
                                    STEP.1
                                </div>
                            </div>
                            <h2 class="description_title">まずは制作したい<br>グッズを選択</h2>
                            <div class="description">
                                鯉口シャツや昇華団扇など、<br>
                                全9種類のカテゴリーの中から選択します。
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="slide">
                            <img class="description_img" src="img/parts/STEP-2.png">
                            <div class="step">
                                <div id="step">
                                    STEP.2
                                </div>
                            </div>
                            <h2 class="description_title">ベースのデザインを選択し<br>簡単お見積り♪</h2>
                            <div class="description">
                                ベースのデザインや個数を選択すると、<br>
                                選択内容に応じたお見積り金額が自動で計算されます。<br>
                                ※お見積り段階の金額となります。
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="slide">
                            <img class="description_img" src="img/parts/STEP-3.png">
                            <div class="step">
                                <div id="step">
                                    STEP.3
                                </div>
                            </div>
                            <h2 class="description_title">お客様情報を入力し<br>ご注文確定！</h2>
                            <div class="description">
                                お客様情報を入力すると、<br>
                                お見積り段階でのご注文が確定します。<br>
                                後日、入力したメールアドレス宛に制作担当者からメールが届きます。
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="slide">
                            <img class="description_img" src="img/parts/STEP-4.png">
                            <div class="step">
                                <div id="step">
                                    STEP.4
                                </div>
                            </div>
                            <h2 class="description_title">メールやLINEで<br>担当者と直接やり取り！</h2>
                            <div class="description">
                                細かいデザインなどは制作担当者と、<br>
                                直接メールやLINEで進めていきます。<br>
                                お支払い方法やグッズのお受け取り方法も柔軟に対応しています。
                            </div>
                        </div>
                    </slide>
                </carousel>

                <div class="btn">
                    <div class="check">
                        <v-ons-checkbox style="padding: 2px 0 0" v-model="check" modifier="material"></v-ons-checkbox>今後表示しない
                    </div>
                    <div class="skip" @click="alert_close">
                        スキップする
                    </div>
                </div>

            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from '../../Common/Toolbar';
    import BottomToolbar from "../../Common/BottomToolbar";

    export default {
        name: "GoodsSelection",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data(){
            return{
                alert: false,
                check: false,
                goods: [
                    {
                        name: '鯉口シャツ',
                        img: '/img/販売管理/商品/鯉口シャツ.jpg',
                        detail: 'ブロード生地(綿100%)と昇華生地を使用'
                    },
                    {
                        name: '団扇',
                        img: '/img/販売管理/商品/昇華団扇.jpg',
                        detail: 'ブロード生地(綿100%)と昇華生地を使用'
                    },
                    {
                        name: '巾着',
                        img: '/img/販売管理/商品/巾着.jpg',
                        detail: 'ブロード生地(綿100%)と昇華生地を使用'
                    },
                    {
                        name: 'タスキ',
                        img: '/img/販売管理/商品/タスキ.jpg',
                        detail: 'ブロード生地(綿100%)と昇華生地を使用'
                    },
                    {
                        name: 'タスキ房',
                        img: '/img/販売管理/商品/タスキ房.jpg',
                        detail: 'ブロード生地(綿100%)と昇華生地を使用'
                    },
                    {
                        name: 'タオル',
                        img: '/img/販売管理/商品/タオル.jpg',
                        detail: 'ブロード生地(綿100%)と昇華生地を使用'
                    },
                    {
                        name: '法被',
                        img: '/img/販売管理/商品/法被.jpg',
                        detail: 'ブロード生地(綿100%)と昇華生地を使用'
                    },
                    {
                        name: 'チョッキ',
                        img: '/img/販売管理/商品/チョッキ.jpg',
                        detail: 'ブロード生地(綿100%)と昇華生地を使用'
                    },
                    {
                        name: 'ロンパース',
                        img: '/img/販売管理/商品/ロンパース.jpg',
                        detail: 'ブロード生地(綿100%)と昇華生地を使用'
                    },
                ],
            }
        },
        mounted() {
            let self = this   //thenの中からdataを操作するため
            let apiVal = {
                uuid: this.$_uuid(),
                type: 'goods'
            }
            let descriptionFlg = this.$_mixAPI_getDescriptionFlg(apiVal)
            descriptionFlg.then(function(val){
                self.alert = val[0]
            });
        },
        methods: {
            alert_close(){
                this.alert = false
                if(this.check == true){
                    let apiVal = {
                        uuid: this.$_uuid(),
                        type: 'goods',
                    }
                    let descriptionFlg = this.$_mixAPI_updateDescriptionFlg(apiVal)
                    descriptionFlg.then(function(val){
                        console.log(val)
                    });
                }
            },
            goodsSelection(val){
                let GoodsDetail = {
                    goods_name:val
                }
                this.$_setGoodsDetail(GoodsDetail)
                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === "goodsDetails");
                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            }
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .info {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        background-color: #f4f4f4;
        text-align: left;
        border-radius: 15px;
        margin: 1%;
        padding: 5% 4%;
    }
    .title {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        margin: 3% 0 2% 5%;
    }
    .goods_list {
        background-color: #f4f4f4;
        border-radius: 15px;
        margin: 5%;
        padding: 5%;
    }
    .content_background {
        background-color: black;
        border-radius: 20px;
    }
    .content {
        position: relative;
        border-radius: 20px;
        box-shadow: 2px 3px 2px rgba(0, 0, 0, .3);
        width: 100%;
    }
    .content:active {
        opacity: 0.7;
    }
    .item_img {
        border-radius: 20px;
        width: 100%;
    }
    .icon {
        box-shadow: inset 5px 5px 8px 0 rgba(0, 0, 0, .5);
        position: absolute;
        top: 10px;
        right: 10px;
        color: black;
        background-color: white;
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
    .content_name {
        background-color: rgba(0, 0, 0, 0.5);
        text-align: left;
        position: absolute;
        border-radius: 20px;
        bottom: 0;
        width: 96%;
        padding: 1% 2% 0 2%;
    }
    .item_name {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        border-bottom: solid 2px #b58012;
        margin: 0;
    }
    .item_detail {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    }

    .overlay {
        z-index:2;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:rgba(0, 0, 0, 0.2);
        top: 1px;
        left:0;
        width:100%;
        height:100%;
    }
    .description_content {
        background-color: #f4f4f4;
        border-radius: 20px;
        width: 90%;
    }
    .slide {
        text-align: left;
    }
    .description_img {
        background-color: #f79b05;
        width: 100%;
        height: 200px;
    }
    .step {
        display: flex;
        flex-wrap: wrap;
        margin: 1% 3% 0;
    }
    #step {
        border: solid 2px black;
        padding: 1% 1% 0;
    }
    .description_title {
        width: 100%;
        margin: 1% 3% 0;
    }
    .description {
        margin: 1% 3% 0;
    }
    .btn {
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        margin: 2% 5%;
    }
    .check {
        font-size: 16px;
        width: 60%;
    }
    .skip {
        font-size: 16px;
        text-align: right;
        color: #3cb8c8;
        width: 40%;
    }
</style>