<!--
  Title        : お気に入り一覧
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
  <v-ons-page>
    <div class="background"></div>
    <Toolbar :action="toggleMenu"></Toolbar>
    <BottomToolbar></BottomToolbar>

    <div class="main" v-show="isShowData">
      <div class="content" v-for="(favorite,index) in res" :key="index" @click="FevClick(favorite.town_id, favorite.festival_id)">
        <div class="favorite">
          <img  id="town_icon" :src="favorite.town_icon" />
          <div class="name">
            <p id="festival">{{favorite.city_name}} {{favorite.festival_name}}</p>
            <h3 id="town_name">{{favorite.town_name}}</h3>
          </div>
          <div class="favorite_icon">
            <img id="icon" src="/img/parts/ハート(赤)-アイコン.png" />
          </div>
        </div>
      </div>
    </div>

    <!--  エラーダイアログ-->
    <v-ons-alert-dialog modifier="rowfooter"
                        :visible.sync="alertErrorVisible">
      <span slot="title">エラーが発生しました</span>
      情報が正しく取得できませんでした。
      <template slot="footer">
        <v-ons-alert-dialog-button @click="alertErrorVisible = false">Ok</v-ons-alert-dialog-button>
      </template>
    </v-ons-alert-dialog>
    <BR /><BR /><BR />
  </v-ons-page>
</template>

<script>
import Toolbar from '../Common/Toolbar'
import BottomToolbar from "../Common/BottomToolbar";

export default {
  name: "Favorite",
  props: ['toggleMenu'],
  components: {
    BottomToolbar,
    Toolbar
  },
  data() {
    return {
      res: [],
      alertLoadingVisible: false,
      alertErrorVisible: false,
      isShowData: false,

      terminal: this.$_Terminal(),
    }
  },
  created() {
    this.alertLoadingVisible = true   //Loadingダイアログ表示開始
    let self = this   //thenの中からdataを操作するため
    let apiVal = {
      uuid: this.$_uuid()
    }
    let getResultAPI = this.$_mixAPI_postGetFavorite(apiVal)
    getResultAPI.then(function(val){
      self.res = val
      self.isShowData = true
    }).catch((err)=>{   //通信エラー時
      if (err.response.status != 404){
        self.isShowData = false   //データ表示領域を不可視
      }
    }).finally(()=>{
      self.alertLoadingVisible = false    //Loadingダイアログを非表示
    });
  },
  methods: {
    FevClick(valTownID, valFestivalID) {
      if(this.terminal == "iPhone"){
        // DeviceOrientationを使用する許可ダイアログを表示
        DeviceOrientationEvent.requestPermission().then(response => {
          // 許可がクリックされた場合
          if (response === "granted") {
            // deviceorientationイベントをlisten
            window.addEventListener('deviceorientation', this.handleOrientation);
          }
        }).catch(console.error).finally(() => {
          this.Fev(valTownID, valFestivalID);
        });
      }else{
        this.Fev(valTownID, valFestivalID);
      }
    },
    Fev(valTownID, valFestivalID){
      this.$_setfestivalIDS(valFestivalID);   //festival_idを広域変数へセット
      this.alertLoadingVisible = true   //Loadingダイアログ表示開始
      let self = this   //thenの中からdataを操作するため
      let apiVal

      apiVal = {
        festival_id: valFestivalID,
        uuid: this.$_uuid()
      }

      let getResultAPI = this.$_mixAPI_getFestivalData(apiVal)
      getResultAPI.then(function(value){
        let FesData = {
          festival_id: self.$_festivalIDS(),
          festival_name:value[0].festival_name,
          festival_type:value[0].festival_type,
          festival_latitude:value[0].festival_latitude,
          festival_longitude:value[0].festival_longitude,
          festival_banner: value[0].festival_banner,
          favorites:value[0].favorites,
          Sponsors:value['Sponsors']
        }
        self.$_setfestivalType(value[0].festival_type);
        self.$_setFestivalData(FesData);
        self.$_setFestivalLatitude(value[0].festival_latitude);
        self.$_setFestivalLongitude(value[0].festival_longitude);
      }).catch((err)=>{   //通信エラー時
        console.log(err);
        self.alertErrorVisible = true   //エラーダイアログ表示
      }).finally(()=>{
        self.alertLoadingVisible = false    //Loadingダイアログを非表示
      });

      apiVal = {
        town_id: valTownID,
        uuid: this.$_uuid()
      }
      let getResultTwn = self.$_mixAPI_postTownData(apiVal);
      getResultTwn.then(function(val){
        let TownDataTmp = {
          festival_id: self.$_festivalIDS(),
          town_id: val[0].town_id,
          purchase_date: val[0].purchase_date,
          carpenter: val[0].carpenter,
          sculpture: val[0].sculpture,
          remarks: val[0].remarks,
          storehouse_latitude: val[0].storehouse_latitude,
          storehouse_longitude: val[0].storehouse_longitude,
          favorites: val[0].favorites,
          town_icon: val[0].town_icon,
          town_img: val[0].town_img,
          hut_img: val[0].hut_img,
          Sponsors: val['Sponsors']
        }
        self.$_setTownData(TownDataTmp);
        if(valTownID == 294){
          //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
          self.$_SetmixPC_ActiveTabIndex(0);
          self.$_SetmixPC_CurrentPage_Key('futontab');
          self.$_setfestivalType(2);
        }else{
          self.$_setTownID(val[0].town_id);
          //タブ用タイトルアイコンデータセット
          let icon4tab = {
            Title: val[0].town_name,
            Icon: val[0].town_icon
          }
          self.$_SetmixPC_TitleIcon4Tab(icon4tab);
          self.$_SetmixPC_CurrentPage_Key('tab');
        }
      }).catch((err)=>{   //通信エラー時
        console.log(err);
        self.alertErrorVisible = true   //エラーダイアログ表示
      }).finally(()=>{
      });
    }
  }
}
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .main{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        background: #fffbea;
        border-radius: 10px;
        opacity: 0.9;
        width: 92%;
        margin: 3%;
        padding: 1%;
    }
    .content {
        background: url("/img/parts/金色屏風-背景.jpg");
        box-shadow: 2px 3px 2px rgba(0, 0, 0, .3);
        border-radius: 10px;
        width: 86%;
        margin: 5%;
        padding: 3% 2%;
    }
    #festival{
      font-size: 14px;
        border-bottom: solid 1px black;
        margin: 1% 0 0;
    }
    .name {
        width: calc(96% - 100px);
        padding: 0 2%;
    }
    .favorite{
        padding-top: 0;
        display: flex;
    }
    .favorite_icon {
      text-align: center;
      background-color: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin: 7px 5px 3px;
    }
    #icon {
      width: 25px;
      height: 25px;
      margin: 8px 0 0 0;
    }
    #town_icon{
        width: 50px;
        height: 50px;
    }
    #town_name{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        font-size: 18px;
    }
</style>
