<!--
  Title        : グッズ紹介（九町連合会）
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <div class="main">
            <div class="goods">
                <div class="name">
                    販売場所
                </div>
                <div class="goods_img">
                    <viewer class="viewer">
                        <img id="img" src="/img/parts/mozu_goods_place.JPG">
                    </viewer>
                </div>
            </div>
            <div class="goods" v-for="(data, index) in res" :key="index">
                <div class="name">
                    商品名：{{ data.goods_name }}
                </div>
                <div class="goods_img">
                    <carousel :per-page="1" :pagination-size="5" :pagination-padding="5">
                        <slide v-for="(value, key) in data.goods_img" :key=key>
                            <viewer class="viewer">
                                <img id="img" :src="value">
                            </viewer>
                        </slide>
                    </carousel>
                </div>
                <div class="description">
                    商品説明<br>
                    {{ data.description }}
                </div>
                <br>
                <div class="amount">
                    金額：{{ data.amount }}円
                </div>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
    export default {
        name: "Goods",
        data() {
            return {
                res: []
            }
        },
        created(){
            let self = this;
            let getResultAPI = this.$_mixAPI_getFutonGoods()
            getResultAPI.then(function(val){
                self.res = val
            }).catch((err)=>{   //通信エラー時
                console.log(err);
                //self.alertErrorVisible = true   //エラーダイアログ表示
            }).finally(()=>{
                self.alertLoadingVisible = false    //Loadingダイアログを非表示
            });
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .main {
        font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border-radius: 10px;
        background-color: white;
        width: 90%;
        margin: 3%;
        padding: 2%;
    }
    .goods {
        border-bottom: black 3px solid;
    }
    .name {
        text-align: left;
        border-bottom: solid 1px black;
        margin: 2%;
    }
    .goods_img {
        width: 96%;
        margin: 5% 2% 3%;
    }
    #img {
        width: 100%;
    }
    .description {
        white-space: pre-line;
        word-break: break-all;
        text-align: left;
        margin: 1% 2%;
    }
    .amount {
        text-align: left;
        margin: 1% 2%;
    }
</style>