<!--
  Title        : 連合選択
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
  <v-ons-page>
    <div class="background"></div>
    <Toolbar :action="toggleMenu"></Toolbar>
    <BottomToolbar></BottomToolbar>

    <div class="top">
      <div class="our_firm">
        <swiper :options="swiperOptions">
          <swiper-slide v-for="(img, index) in our_film_img" :key="index">
            <img class="our_firm_img" :src="img" @click="bannerPageTransition(index)">
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>

    <div class="advice">
      <label>地区をお選びください</label>
    </div>

    <div>
      <div class="local" v-for="(pref,index) in res" :key="`first-${index}`">
        <h3 id="local_name">{{ pref.prefecture_name }}</h3>
        <div class="city" v-for="(city,index) in pref.city_data" :key="`second-${index}`">
          <input :id="'acd-check'+city.city_id" class="acd-check" type="checkbox">
          <label class="acd-label" :for="'acd-check'+city.city_id">
            <div class="festival_is_underway">
              <span class="festival_status" v-show="city.advance === 1">祭礼開催予定</span>
              <span class="festival_status" v-show="city.advance === 2">祭礼開催中</span>
            </div>
            <div class="city_name">
              <div id="city_name">
                {{ city.city_name }}
              </div>
              <img class="icon" id="plus" src="/img/parts/プラス-アイコン.png">
              <img class="icon" id="minus" src="/img/parts/マイナス-アイコン.png">
            </div>
          </label>
          <div class="acd-content">
            <div class="district" v-for="(fval,index) in city.festivals" :key="`third-${index}`">
              <div class="town_is_underway">
                <img id="festival_is_underway_img" src="img/bg_okiniiribtn.png" v-show="fval.advance_notice === 1 || fval.advance_notice === 2">
                <span class="town_status" v-show="fval.advance_notice === 1">祭礼開催予定</span>
                <span class="town_status" v-show="fval.advance_notice === 2">祭礼開催中</span>
                <img id="festival_is_underway_img_transform" src="img/bg_okiniiribtn.png" v-show="fval.advance_notice === 1 || fval.advance_notice === 2">
              </div>
              <div id="town_select_btn" @click="push(fval)">
                <div class="festival_name">{{fval.festival_name}}</div>
                <img class="icon2" src="/img/parts/右矢印-アイコン.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  ダイアログ系-->
    <!--  ローディングダイアログ-->
    <v-ons-alert-dialog modifier="rowfooter"
                        :visible.sync="alertLoadingVisible">
      <Spinner message="データ取得中...." />
    </v-ons-alert-dialog>

    <v-ons-alert-dialog modifier="rowfooter"
                        :visible.sync="alertUUIDErrorVisible">
      <span slot="title">エラーが発生しました</span>
      UUIDが正しく取得できませんでした。お気に入り機能、投稿機能などが現在使えません。
      <template slot="footer">
        <v-ons-alert-dialog-button @click="movePage(FestivalIDForUUIDError);alertUUIDErrorVisible = false">Ok</v-ons-alert-dialog-button>
      </template>
    </v-ons-alert-dialog>
    <BR /><BR /><BR />

  </v-ons-page>
</template>

<script>
  import Toolbar from "../Common/Toolbar";
  import BottomToolbar from "../Common/BottomToolbar";
  import Spinner from "vue-simple-spinner";

  export default {
    name: "Select",
    props: ['toggleMenu'],
    components: {
      Toolbar,
      BottomToolbar,
      Spinner,
    },
    data() {
      return {
        festival_type: this.$_festivalType(),
        swiperOptions: {
          slidesPerView: 1,
          spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          loop: true,
          autoplay: {
            delay: 5000, // 途切れなくループ
            reverseDirection: false,
          },
        },
        our_film_img:[
          "/img/our_film/FMOsaka_banner.png",
          "/img/our_film/townjoining_banner.png",
          "/img/our_film/sponsor_banner.png",
          "/img/our_film/goods_sales_banner.png",
        ],
        res: [],
        newArrivalRes: [],
        beforPref: "",
        showChild: true,
        FestivalIDForUUIDError:0,
        alertLoadingVisible: false,
        alertUUIDErrorVisible: false,
      }
    },
    mounted: function() {
      this.showChild = false;
      this.$nextTick(() => (this.showChild = true));
    },
    created: function() {
      this.alertLoadingVisible = true   //Loadingダイアログ表示開始
      let self = this   //thenの中からdataを操作するため
      let apiVal = {
        festival_type: this.$_festivalType()
      }
      let getResultAPI = this.$_mixAPI_getFestivals(apiVal)
      getResultAPI.then(function(val){
        self.res = val
      }).catch((err)=>{   //通信エラー時
        console.log(err)
        // if (err.response.status != 404){
        //   self.alertErrorVisible = true   //エラーダイアログ表示
        // }
      }).finally(()=>{
        self.alertLoadingVisible = false    //Loadingダイアログを非表示
      });
      let newArrivals = this.$_mixAPI_UpTo3Notifications()
      newArrivals.then(function(val){
        self.newArrivalRes = val
      })
    },
    methods: {
      bannerPageTransition(val){
        this.$_setPage('Select')
        switch (val) {
          case 0:
            return(
             this.$_setSponsor_id(118),
             //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
             this.$_SetmixPC_CurrentPage_Key('sponsorDetailTab')
            );
          case 3:
            return(
             //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
             this.$_SetmixPC_CurrentPage_Key('goodsSelection')
            )
          default:
            return(
             //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
             this.$_SetmixPC_CurrentPage_Key('inquiry')
            )
        }
      },
      push(val) {
        this.$_setfestivalType(val.festival_type)
        if (this.$_uuid() == ''){
          this.alertUUIDErrorVisible = true
          this.FestivalIDForUUIDError = val
        } else {
          this.alertLoadingVisible = true   //Loadingダイアログ表示開始
          let self = this   //thenの中からdataを操作するため
          let apiVal = {
            festival_id: val.festival_id,
            uuid: this.$_uuid()
          }

          self.$_setfestivalIDS(val.festival_id)
          self.$_setCityID(val.city_id)

          let getResultAPI = this.$_mixAPI_getFestivalData(apiVal)
          getResultAPI.then(function(value){
            let FesData = {
              festival_id: self.$_festivalIDS(),
              festival_name:value[0].festival_name,
              festival_latitude:value[0].festival_latitude,
              festival_longitude:value[0].festival_longitude,
              festival_banner: value[0].festival_banner,
              favorites:value[0].favorites,
              Sponsors:value['Sponsors']
            }

            self.$_setFestivalData(FesData);

            self.$_setFestivalLatitude(value[0].festival_latitude);
            self.$_setFestivalLongitude(value[0].festival_longitude);

            //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
            self.$_SetmixPC_CurrentPage_Key('menu')

          }).catch((err)=>{   //通信エラー時
            console.log(err);
            self.alertErrorVisible = true   //エラーダイアログ表示
          }).finally(()=>{
            self.alertLoadingVisible = false    //Loadingダイアログを非表示
          });

        }
      },
      movePage(val) {
        this.$_setfestivalIDS(val)
        //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
        this.$_SetmixPC_CurrentPage_Key('menu')
      },
      newArrivals(){
        this.$_setPage('')
        return(
          //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
          this.$_SetmixPC_CurrentPage_Key('newArrivals')
        );
      },
    }
  }
</script>

<style scoped>
  .background{
    background: black;
  }
  .top {
    background-image: url("/img/parts/スライダー-背景.jpg");
    background-size: 100% 260px;
    background-repeat: no-repeat;
    width: 100%;
    height: 260px;
  }
  .our_firm{
    border-radius: 20px;
    top: 10px;
    width: calc(100% - 40px);
    padding: 20px;
  }
  .our_firm_img{
    width: 100%;
    height: 210px;
  }
  .advice {
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    text-align: left;
    font-size: 20px;
    margin: 4% 5%
  }
  .local{
    overflow: hidden;
    border-radius: 20px;
    margin: 5px 15px 20px;
  }
  #local_name{
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background: url("/img/parts/金色屏風-背景.jpg");
    font-size: 33px;
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000,
    -1px 1px 0 #000, 1px -1px 0 #000,
    0 1px 0 #000,  0 -1px 0 #000,
    -1px 0 0 #000, 1px 0 0 #000;
    text-align: left;
    border-bottom: 1px solid black;
    margin: 0;
    padding: 6% 3%;
  }
  .city{
    overflow: hidden;
  }

  .festival_is_underway{
    /*position: absolute;*/
    text-align: left;
    width: 100%;
    height: 25px;
    margin: 0;
    top: 0;
    left: 0;
    color: white;
    background-color: #fffbea;
  }
  #festival_is_underway_img{
    height: 30px;
    vertical-align: middle;
  }
  #festival_is_underway_img_transform{
    height: 30px;
    vertical-align: middle;
    transform: scale(-1, 1)
  }
  .festival_status {
    font-family: 'Sawarabi Mincho', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-fnt-smoothing: grayscale;
    vertical-align: middle;
    color: red;
    font-size:20px;
  }
  .town_is_underway{
    text-align: left;
    width: 100%;
    height: 25px;
    color: white;
    background-color: white;
  }
  .town_status {
    font-family: 'Sawarabi Mincho', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-fnt-smoothing: grayscale;
    vertical-align: middle;
    color: red;
    font-size:20px;
  }

  .city_name {
    display: flex;
    flex-wrap: wrap;
  }
  #city_name {
    font-size: 33px;
    width: calc(100% - 40px);
  }

  .acd-label {
    position: relative;
    display: block;
    text-align: left;
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fffbea;
    border-bottom: 1px solid black;
    margin: 0;
    padding: 0 3% 20px;
  }
  .acd-check {
    display: none;
  }
  .acd-content {
    width: 100%;
    height: 0;
    opacity: 0;
    transition: .5s;
    visibility: hidden;
    display: grid;
  }
  .acd-check:checked + .acd-label #plus {
    display: none;
  }
  .acd-check:checked + .acd-label + .acd-content {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
  .district{
    text-align: center;
    background: white;
    border-bottom: solid 1px black;
    width: 100%;
  }
  #town_select_btn{
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-wrap: wrap;
    color: black;
    background-color: white;
    position: relative;
    text-align: left;
    width: calc(100% - 6%);
    padding: 0 3% 20px;
  }
  .festival_name {
    font-size: 25px;
    width: calc(100% - 30px);
    white-space: pre-line;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0 0 4% 0;
    font-size: 95%;
  }
  h2 span {
    border-bottom: #b58012 2px solid;
    padding: 0 5% 2% 5%;
  }
  .icon {
    position: absolute;
    right: 15px;
    height: 30px;
    width: 30px;
    margin: 10px 0 0;
  }
  .icon2 {
    right: 15px;
    height: 25px;
    width: 25px;
    margin: 5px 0 0;
  }
</style>
