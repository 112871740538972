<!--
  Title        : タブメイン
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
  <v-ons-page>
    <Toolbar :action="toggleMenu"></Toolbar>
    <TabBar style="z-index:1;padding-top: 1px"/>
    <BottomToolbar></BottomToolbar>

    <div class="overlay" v-show="alert">
      <div class="content" slot="title">
        <carousel
                :per-page="1"
                :pagination-enabled="true"
                :pagination-size="8"
                :pagination-padding="5"
                :autoplay="false"
                :loop="false"
                :autoplayHoverPause="true">
          <slide>
            <div class="slide">
              <img src="img/popup/tab/basic-function-popup.png">
              <div class="step"><div id="step">STEP.1</div>
              </div>
              <h2 class="title">基本機能について</h2>
              <div class="description">
                お気に入り機能<br>
                右上のハートのアイコンタップすると、選択中の町をお気に入り登録することができます。<br>
                メニューからいつでも、お気に入りの町にアクセスできます。
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <img src="img/popup/tab/map-popup.png">
              <div class="step">
                <div id="step">STEP.2</div>
              </div>
              <h2 class="title">地車現在地について</h2>
              <div class="description">
                だんじりや太鼓台の現在地が確認できます<br>
                家のアイコンタップすると「だんじり小屋」の位置が表示され、トイレのアイコンをタップすると「仮設トイレ」の位置が表示されます。
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <img src="img/popup/tab/course-popup.png">
              <div class="step">
                <div id="step">STEP.3</div>
              </div>
              <h2 class="title">コースについて</h2>
              <div class="description">
                各町のタイムスケジュール、コース表等を祭礼当日に掲載されます。
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <img src="img/popup/tab/basic-information-popup.png">
              <div class="step">
                <div id="step">STEP.4</div>
              </div>
              <h2 class="title">基本情報について</h2>
              <div class="description">
                だんじりや布団太鼓の基本情報です。<br>
                大工や彫師、彫り物に関する 情報を掲載しています。
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <img src="img/popup/tab/sponsor-popup.png">
              <div class="step">
                <div id="step">STEP.5</div>
              </div>
              <h2 class="title">スポンサーについて</h2>
              <div class="description">
                地区で応援していただいている協賛スポンサー様を掲載しております。
                中には地域の活性化として、お得なクーポンを 発行していただいている企業様もございますので、是非一度企業詳細を覗いてみてください。
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <img src="img/popup/tab/push.png">
              <div class="step">
                <div id="step">STEP.6</div>
              </div>
              <h2 class="title">お知らせについて</h2>
              <div class="description">
                町からのお知らせです。
                自身のお好きな町をお気に入り登録していただくことでその町の最新情報をプッシュ通知で受けることができます。
              </div>
            </div>
          </slide>
        </carousel>

        <div class="btn">
          <div class="check">
            <v-ons-checkbox style="padding: 2px 0 0" v-model="check" modifier="material"></v-ons-checkbox>今後表示しない
          </div>
          <div class="skip" @click="alert_close">
            スキップする
          </div>
        </div>
      </div>
    </div>

  </v-ons-page>
</template>

<script>
import Toolbar from '../Common/Toolbar'
import TabBar from '@/components/Common/Tabbar';
import BottomToolbar from "../Common/BottomToolbar";

export default {
  name: "TabMain",
  props: ['toggleMenu'],
  components: {
    Toolbar,
    TabBar,
    BottomToolbar
  },
  data() {
    return {
      activeIndex: this.$_mixPC_ActiveTabIndex(),
      tabs: this.$_mixPC_Tabmenu_init(),
      alert: false,
      check: false,
    }
  },
  mounted() {
    this.$_setPage('Tab')

    let self = this   //thenの中からdataを操作するため
    let apiVal = {
      uuid: this.$_uuid(),
      type: 'tab'
    }
    let descriptionFlg = this.$_mixAPI_getDescriptionFlg(apiVal)
    descriptionFlg.then(function(val){
      self.alert = val[0]
    });
  },
  methods:{
    alert_close(){
      this.alert = false
      if(this.check == true){
        let apiVal = {
          uuid: this.$_uuid(),
          type: 'tab',
        }
        this.$_mixAPI_updateDescriptionFlg(apiVal)
      }
    },
  }
}
</script>

<style scoped>
  .overlay {
    z-index:2;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(0, 0, 0, 0.2);
    top: 1px;
    left:0;
    width:100%;
    height:100%;
  }
  .content {
    background-color: #f4f4f4;
    width: 90%;
  }
  .slide {
    background-color: rgba(0, 0, 0, 0);
    text-align: left;
  }
  img {
    background-color: #f79b05;
    width: 100%;
    height: 200px;
  }
  .step {
    display: flex;
    flex-wrap: wrap;
    margin: 1% 10%;
  }
  #step {
    border: solid 2px black;
    padding: 1% 1% 0;
  }
  .title {
    margin: 1% 10%;
  }
  .description {
    margin: 0 10%;
  }
  .btn {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    margin: 2% 5%;
  }
  .check {
    font-size: 16px;
    width: 60%;
  }
  .skip {
    font-size: 16px;
    text-align: right;
    color: #3cb8c8;
    width: 40%;
  }
</style>