// *******************************
// config.json読み込み用Mixinファイル
// ******************************

const config = require('../../config.json');

export const mixConfig = {
    data () {
        return {
            mixCF_config: config
        }
    }
}