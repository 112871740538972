<template>
    <v-ons-page>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>
        <div class="background"></div>

        <div>
            <div class="local">
                <h3 id="local_name">機能説明</h3>
                <div class="city" v-for="(val, index) in res" :key="index">
                    <input :id="'acd-check'+index" class="acd-check" type="checkbox">
                    <label class="acd-label" :for="'acd-check'+index">
                        {{ val.title }}
                        <img class="icon" id="plus" src="/img/parts/プラス-アイコン.png">
                        <img class="icon" id="minus" src="/img/parts/マイナス-アイコン.png">
                    </label>
                    <div class="acd-content">
                        <div class="district">
                            <div class="description_content" slot="title">
                                <carousel
                                        :per-page="1"
                                        :pagination-enabled="true"
                                        :pagination-size="8"
                                        :pagination-padding="5"
                                        :autoplay="false"
                                        :loop="false"
                                        :autoplayHoverPause="true">
                                    <slide v-for="(value, index) in val.data" :key="index">
                                        <div class="slide">
                                            <img class="description_img" :src="value.img">
                                            <div class="step" v-show="value.step !== ''">
                                                <div id="step">
                                                    {{ value.step }}
                                                </div>
                                            </div>
                                            <h2 class="title">{{ value.title }}</h2>
                                            <div class="description">
                                                {{ value.description }}
                                            </div>
                                        </div>
                                    </slide>
                                </carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "Guide",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data(){
            return{
                res: [
                    {
                        title: "アプリについて",
                        data: [
                            {
                                img: "img/popup/main/tsuiseki.png",
                                step: "",
                                title: "追跡について",
                                description: "追跡を押すと地区選択画面に移動します。\n" +
                                    "地区選択画面から連合を選択して頂きます。\n" +
                                    "連合選択すると連合の画面に移動し、祭り本番時であればその連合の地車、太鼓台がリアルタイムでマップ上に表示されます。",
                            },
                            {
                                img: "img/popup/main/news.png",
                                step: "",
                                title: "お知らせ機能について",
                                description: "地車＆太鼓アプリに加入していただいている町から投稿されたお知らせをご確認いただけます。\n" +
                                    "さらに自身のお好きな町をお気に入り登録していただくことでその町の最新情報をプッシュ通知で受けることができます。",
                            },
                            {
                                img: "img/popup/main/sairei.png",
                                step: "",
                                title: "祭礼日程表について",
                                description: "祭礼日程、行事日程をご確認いただけます。\n" +
                                    "これによりいつどこで祭りが開催されているのかが分かります。",
                            },
                            {
                                img: "img/popup/main/sponsor.png",
                                step: "",
                                title: "協賛スポンサーについて",
                                description: "地車アプリに応援、協力、協賛していただいている。\n" +
                                    "スポンサー様を一覧でご確認いただけます。\n" +
                                    "このアプリはスポンサー様の協力、応援のおかげで運営し、成り立っております。",
                            },
                            {
                                img: "img/popup/main/sales.png",
                                step: "",
                                title: "グッズ制作、販売について",
                                description: "アプリ内でタオル、タスキ、団扇等を簡単・低価格・高品質で注文いただけます。\n" +
                                    "手間だった作業もアプリ内で解決できます。",
                            },
                        ]
                    },
                    {
                        title: "販売管理",
                        data: [
                            {
                                img: "img/parts/STEP-1.png",
                                step: "STEP.1",
                                title: "まずは制作したい\n" + "グッズを選択",
                                description: "鯉口シャツや昇華団扇など\n" +
                                             "全9種類のカテゴリーの中から選択します。",
                            },
                            {
                                img: "img/parts/STEP-2.png",
                                step: "STEP.2",
                                title: "ベースのデザインを選択し\n" + "簡単お見積り♪",
                                description: "ベースのデザインや個数を選択すると、\n" +
                                             "選択内容に応じたお見積り金額が自動で計算されます。\n" +
                                             "※お見積り段階の金額となります。",
                            },
                            {
                                img: "img/parts/STEP-3.png",
                                step: "STEP.3",
                                title: "お客様情報を入力し\n" + "ご注文確定！",
                                description: "お客様情報を入力すると、\n" +
                                             "お見積り段階でのご注文が確定します。\n" +
                                             "後日、入力したメールアドレス宛に制作担当者からメールが届きます。",
                            },
                            {
                                img: "img/parts/STEP-4.png",
                                step: "STEP.4",
                                title: "メールやLINEで\n" + "担当者と直接やり取り！",
                                description: "細かいデザインなどは制作担当者と、\n" +
                                             "直接メールやLINEで進めていきます。\n" +
                                             "お支払い方法やグッズのお受け取り方法も柔軟に対応しています。",
                            },
                        ]
                    },
                    {
                        title: "町専用ページ",
                        data: [
                            {
                                img: "img/popup/tab/basic-function-popup.png",
                                step: "STEP.1",
                                title: "基本機能について",
                                description: "お気に入り機能\n" +
                                             "右上のハートのアイコンタップすると、選択中の町をお気に入り登録することができます。\n" +
                                             "メニューからいつでも、お気に入りの町にアクセスできます。",
                            },
                            {
                                img: "img/popup/tab/map-popup.png",
                                step: "STEP.2",
                                title: "地車現在地について",
                                description: "だんじりや布団太鼓の現在地が確認できます\n" +
                                             "家のアイコンタップすると「小屋」の位置が表示され、トイレのアイコンをタップすると「仮設トイレ」の位置が表示されます。",
                            },
                            {
                                img: "img/popup/tab/course-popup.png",
                                step: "STEP.3",
                                title: "コースについて",
                                description: "各町のタイムスケジュール、コース表等を祭礼当日に掲載されます。",
                            },
                            {
                                img: "img/popup/tab/basic-information-popup.png",
                                step: "STEP.4",
                                title: "基本情報について",
                                description: "だんじりや布団太鼓の基本情報です。\n" +
                                             "大工や彫師、彫り物に関する 情報を掲載しています。",
                            },
                            {
                                img: "img/popup/tab/sponsor-popup.png",
                                step: "STEP.5",
                                title: "スポンサーについて",
                                description: "地区で応援していただいている協賛スポンサー様を掲載しております。" +
                                             "中には地域の活性化として、お得なクーポンを 発行していただいている企業様もございますので、是非一度企業詳細を覗いてみてください。",
                            },
                            {
                                img: "img/popup/tab/push.png",
                                step: "STEP.6",
                                title: "お知らせについて",
                                description: "町からのお知らせです。\n" +
                                             "自身のお好きな町をお気に入り登録していただくことでその町の最新情報をプッシュ通知で受けることができます。",
                            },
                        ]
                    }
                ]
            }
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .local{
        overflow: hidden;
        border-radius: 20px;
        margin: 5px 15px 20px;
    }
    #local_name{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: white;
        background: url("/img/parts/金色屏風-背景.jpg");
        font-size: 33px;
        text-shadow: 1px 1px 0 #000, -1px -1px 0 #000,
        -1px 1px 0 #000, 1px -1px 0 #000,
        0 1px 0 #000,  0 -1px 0 #000,
        -1px 0 0 #000, 1px 0 0 #000;
        text-align: left;
        border-bottom: 1px solid black;
        margin: 0;
        padding: 6% 3%;
    }
    .city{
        overflow: hidden;
    }
    .acd-label {
        position: relative;
        display: block;
        text-align: left;
        font-size: 33px;
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #fffbea;
        border-bottom: 1px solid black;
        margin: 0;
        padding: 6% 3%;
    }
    .acd-check {
        display: none;
    }
    .acd-content {
        width: 100%;
        height: 0;
        opacity: 0;
        transition: .5s;
        visibility: hidden;
        display: grid;
    }
    .acd-check:checked + .acd-label #plus {
        display: none;
    }
    .acd-check:checked + .acd-label + .acd-content {
        height: auto;
        opacity: 1;
        visibility: visible;
    }
    .district{
        text-align: center;
        background: white;
        border-bottom: solid 1px black;
        width: 100%;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 0 0 4% 0;
        font-size: 95%;
    }
    h2 span {
        border-bottom: #b58012 2px solid;
        padding: 0 5% 2% 5%;
    }
    .icon {
        position: absolute;
        top: 25px;
        right: 15px;
        height: 20px;
        width: 20px;
    }
    #plus {
        position: absolute;
        top: 33px;
        right: 15px;
        height: 30px;
        width: 30px;
    }
    #minus {
        position: absolute;
        top: 33px;
        right: 15px;
        height: 30px;
        width: 30px;
    }

    .description_content {
        background-color: #f4f4f4;
        width: 100%;
    }
    .slide {
        text-align: left;
    }
    .description_img {
        background-color: #f79b05;
        width: 100%;
    }
    .step {
        display: flex;
        flex-wrap: wrap;
        margin: 2% 5% 0;
    }
    #step {
        border: solid 2px black;
        padding: 1% 1% 0;
    }
    .title {
        white-space:pre-wrap;
        word-wrap:break-word;
        font-size: 25px;
        width: 100%;
        margin: 3% 5% 0;
    }
    .description {
        white-space:pre-wrap;
        word-wrap:break-word;
        margin: 3% 5%;
        font-size: 16px;
    }
</style>