<!--
  Title        : 基本情報
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>

        <div class="main">
            <viewer class="viewer">
                <img id="jiguruma_img" :src="townData.town_img">
            </viewer>
            <table>
                <tr>
                    <th><div class="title">新調、購入日</div></th>
                    <td><div class="content">{{townData.purchase_date}}</div></td>
                </tr>
                <tr>
                    <th><h5 class="title">大工、彫師</h5></th>
                    <td><div class="content">{{townData.carpenter}}</div></td>
                </tr>
                <tr>
                    <th><h5 class="title">主な彫物</h5></th>
                    <td><div class="content">{{townData.sculpture}}</div></td>
                </tr>
                <tr>
                    <th><h5 class="title">備考</h5></th>
                    <td><div class="content">{{townData.remarks}}</div></td>
                </tr>
            </table>
        </div>
    </v-ons-page>
</template>

<script>
    export default {
        name: "BaseInfo",
        data(){
            return{
                townData: new Object(),
                res: [],
                alertLoadingVisible: false,
                alertErrorVisible: false,
                modalVisible: false,
                festival_id: this.$_festivalIDS(),
                townImg: '',
            }
        },
        created: function() {
            this.townData = this.$_TownData();
            this.townImg = this.townData.town_img
        },
        methods: {
            push(Val){
                this.$_setPage('SponsorList')
                return(
                    this.$_setSponsor_id(Val),
                    //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                    this.$_SetmixPC_CurrentPage_Key('sponsorDetailTab')
                );
            },
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    table{
        width: 100%;
    }
    th{
        background-color: #b99200;
        color: black;
        padding: 1vh 2vw;
        text-align: left;
        width: 31%;
    }
    td{
        word-wrap: break-word;
        background-color: white;
        padding: 1vh 2vw;
        text-align: left;
        font-size: 14px;
        width: 69%;
    }
    .main{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 3% 1%;
        height: auto;
    }
    #jiguruma_img{
        width: calc(100% - 4px);
        vertical-align: top;
    }
    .title{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .content{
        white-space: pre-wrap;
        word-wrap: break-word;
    }
</style>
