<!--
  Title        : タブバー
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
  <v-ons-tabbar id="tab" position="top"
                :swipeable="[false, true, true, true, true]"
                :tabs="tabs"
                :visible="true"
                :index.sync="activeIndex"
                :tab-border="true"
  >
  </v-ons-tabbar>
</template>

<script>
export default {
  name: "Tabbar",
  data() {
    return {
      activeIndex: this.$_mixPC_ActiveTabIndex(),
      tabs: this.$_mixPC_Tabmenu_init(),
    }
  },
  mounted() {
    // Create a new stylesheet in the document
    let styleSheet = document.createElement('style');
    document.head.appendChild(styleSheet);

    // Use insertRule() to add a new rule to the stylesheet
    styleSheet.sheet.insertRule('.tabbar__border { width: 20% !important; }', 0);

    switch(this.$_festivalType()) {
      case 1:
        this.tabs[0].label = '地車現在地'
        return
      case 2:
        this.tabs[0].label = '太鼓現在地'
        return
      case 3:
        this.tabs[0].label = '祇園車現在地'
        return
    }

  }
}
</script>

<style>
  .tabbar {
    list-style-type: none !important;
  }
  .tabbar__item {
    border-left: 1px solid #c0c0c0;
    margin-left: -1px;
  }
  .ons-tabbar__footer{
    border-top: solid 1px #c0c0c0;
  }
  .tabbar__label {
    font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
    font-size: 12px !important;
    text-align: center !important;
  }
  .tabbar--top__button {
    background-color: white !important;
  }
  .tabbar__button {
    color: black !important;
    background-color: white !important;
  }
  :checked + .tabbar__button {
    color: black !important;
    background-color: white !important;
  }
  :checked + .tabbar__button .tabbar__label{
    font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>