<!--
  Title        : ユーザー情報入力フォーム
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div style="color: white;text-align: left">
            <h2 class="title">お客様情報のご入力</h2>
            <div class="title">お客様情報をご入力ください。</div>
        </div>

        <div class="main">
            <div class="form">
                <div class="form_name">
                    氏名
                </div>
                <div class="input_form">
                    <v-ons-input class="input" type="text" name="name" v-model="name"/>
                </div>
                <div class="validate" v-show="name_validate">
                    ※氏名を入力してください
                </div>
            </div>

            <div class="form">
                <div class="form_name">
                    性別
                </div>
                <div>
                    <v-ons-select class="gender_select" name="gender" v-model="gender">
                        <option name="gender" value="男性">男性</option>
                        <option name="gender" value="女性">女性</option>
                        <option name="gender" value="その他">その他</option>
                    </v-ons-select>
                </div>
                <div class="validate" v-show="gender_validate">
                    ※性別を選択してください
                </div>
            </div>

            <div class="form">
                <div class="form_name">
                    生年月日
                </div>
                <div class="input_form">
                    <v-ons-select class="birth_day" name="year" v-model="year">
                        <option name="year" v-for="(year, index) in years" :key="index">{{ year }}</option>
                    </v-ons-select>年
                    <v-ons-select class="birth_day" name="month" v-model="month">
                        <option name="month" v-for="(month, index) in months" :key="index">{{ month }}</option>
                    </v-ons-select>月
                    <v-ons-select class="birth_day" name="day" v-model="day">
                        <option name="day" v-for="(day, index) in days" :key="index">{{ day }}</option>
                    </v-ons-select>日
                </div>
                <div class="validate" v-show="birthday_validate">
                    ※生年月日を入力してください
                </div>
            </div>

            <div class="form">
                <div class="input_form">
                    <div class="form_name">
                        郵便番号
                    </div>
                    <div style="display: flex">
                        <v-ons-input class="postal_code" type="text" v-model="postal_code" inputmode="numeric" maxlength="7" size="8"/>
                        <v-ons-button class="address_search" @click="searchAddress()">自動入力</v-ons-button>
                    </div>
                    <div class="warning">
                        ※ハイフンなしで入力してください
                    </div>
                    <div class="validate" v-show="postal_code_validate">
                        ※半角英数字で有効な7桁の郵便番号を入力してください
                    </div>
                    <div style="margin: 3% 0 1% 0">
                        都道府県
                    </div>
                    <div>
                        <v-ons-select class="pref_select" v-model="prefecture">
                            <option value="大阪府">大阪府</option>
                        </v-ons-select>
                    </div>
                    <div style="margin: 3% 0 1% 0">
                        市区町村
                    </div>
                    <div>
                        <v-ons-input class="input" v-model="town" type="text"/>
                    </div>
                    <div style="margin: 3% 0 1% 0">
                        番地
                    </div>
                    <div>
                        <v-ons-input class="input" v-model="city" type="text"/>
                    </div>
                    <div style="margin: 3% 0 1% 0">
                        建物名・部屋番号
                    </div>
                    <div>
                        <v-ons-input class="input" v-model="building" type="textarea"/>
                    </div>
                </div>
            </div>

            <div class="form">
                <div class="form_name">
                    メールアドレス
                </div>
                <div class="input_form">
                    <v-ons-input class="input" type="email" name="email" v-model="email"/>
                </div>
                <div class="validate" v-show="email_validate">
                    ※半角英数字で有効なメールアドレスを入力してください
                </div>
            </div>

            <div class="explanation">
                <div class="form_name">
                    使用目的
                </div>
                <div>
                    <input type="radio" id="option1" value="個人用" v-model="intended_use_radio"/>
                    <label for="option1">個人用</label>
                    <br>
                    <input type="radio" id="option2" value="団体" v-model="intended_use_radio"/>
                    <label for="option2">団体</label>
                    <br>
                    <input type="radio" id="option3" value="プレゼント用" v-model="intended_use_radio"/>
                    <label for="option3">プレゼント用</label>
                    <br>
                    <input type="radio" id="option4" value="その他" v-model="intended_use_radio"/>
                    <label for="option3">その他（自由記載）</label>
                </div>
                <textarea class="textarea" id="text_area" v-model="intended_use_text"></textarea>
            </div>

            <v-ons-button class="check_button" v-show="true" @click="PageTransition('checkDetails')">ご注文内容の確認</v-ons-button>
            <v-ons-button class="check_button_none" v-show="false" @click="PageTransition('checkDetails')">ご注文内容の確認</v-ons-button>

        </div>

        <!--  ローディングダイアログ-->
        <v-ons-alert-dialog modifier="rowfooter"
                            :visible.sync="alertLoadingVisible">
            <Spinner message="データ取得中...." />
        </v-ons-alert-dialog>

    </v-ons-page>
</template>

<script>
    import Toolbar from '../../Common/Toolbar';
    import BottomToolbar from "../../Common/BottomToolbar";
    import Spinner from "vue-simple-spinner";

    export default {
        name: "UserInformation",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
            Spinner
        },
        data(){
            return{
                name: '',
                gender: '',
                email: '',
                year: '',
                month:'',
                day: '',
                postal_code:'',
                prefecture: '',
                town: '',
                city: '',
                building: '',
                intended_use_radio: '',
                intended_use_text: '',

                name_validate: false,
                gender_validate: false,
                email_validate: false,
                birthday_validate: false,
                postal_code_validate: false,

                alertLoadingVisible: false,
            }
        },
        computed:{
            years() {
                const years = []
                for (let year = 1900; year <= new Date().getFullYear(); year++) {
                    years.push(year)
                }
                return years
            },
            months() {
                const months = [...Array(12)].map((ele, i) => i + 1)
                return months
            },
            days() {
                let days = [];
                const selectedMonth = parseInt(this.month);
                if (selectedMonth === 2) {
                    // February
                    const isLeapYear = (this.year % 4 === 0 && this.year % 100 !== 0) || (this.year % 400 === 0);
                    days = isLeapYear ? [...Array(29)].map((ele, i) => i + 1) : [...Array(28)].map((ele, i) => i + 1);
                } else if (selectedMonth === 4 || selectedMonth === 6 || selectedMonth === 9 || selectedMonth === 11) {
                    // April, June, September, November
                    days = [...Array(30)].map((ele, i) => i + 1);
                } else {
                    // Other months
                    days = [...Array(31)].map((ele, i) => i + 1);
                }
                return days;
            }
        },
        created() {
            this.name = this.$_UserInfo().name
            this.gender = this.$_UserInfo().gender
            this.email = this.$_UserInfo().email
            this.year = this.$_UserInfo().year
            this.month = this.$_UserInfo().month
            this.day = this.$_UserInfo().day
            this.postal_code = this.$_UserInfo().postal_code
            this.prefecture = this.$_UserInfo().prefecture
            this.city = this.$_UserInfo().city
            this.town = this.$_UserInfo().town
            this.building = this.$_UserInfo().building
            this.intended_use_radio = this.$_UserInfo().intended_use_radio
            this.intended_use_text = this.$_UserInfo().intended_use_text
        },
        methods: {
            searchAddress() {
                let self = this
                this.postal_code_validate = false
                if(this.postal_code == ''){
                    this.postal_code_validate = true
                }else{
                    this.alertLoadingVisible = true   //Loadingダイアログ表示開始
                    let apiVal = {
                        postcode: this.postal_code
                    }
                    let getResultAPI = this.$_mixAPI_AddressSearch(apiVal)
                    getResultAPI.then(function(val){
                        self.prefecture = val.pref
                        self.town = val.address
                        self.city = val.city
                    }).catch((err)=>{   //通信エラー時
                        console.log(err);
                        this.postal_code_validate = true
                    }).finally(()=>{
                        self.alertLoadingVisible = false   //Loadingダイアログ表示終了
                    });
                }
            },
            PageTransition(val){
                this.name_validate = false
                this.gender_validate = false
                this.birthday_validate = false
                this.email_validate = false

                if(this.name == ''){
                    this.name_validate = true
                    return
                }
                if(this.gender == ''){
                    this.gender_validate = true
                    return
                }
                if(this.year == '' || this.month == '' || this.day == ''){
                    this.birthday_validate = true
                    return
                }
                if(this.email == ''){
                    this.email_validate = true
                    return
                }
                if(this.email){
                    //メールアドレスとして判定される文字列と記号の組み合わせを定数化
                    const reg = new RegExp(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/);
                    //指定した組み合わせになっていなかった場合判定を返す。
                    console.log(reg.test(this.email))
                    if(reg.test(this.email) == false){
                        this.email_validate = true
                        return
                    }
                }
                let UserInfo = {
                    name: this.name,
                    gender: this.gender,
                    email: this.email,
                    year: this.year,
                    month: this.month,
                    day: this.day,
                    postal_code: this.postal_code,
                    prefecture: this.prefecture,
                    city: this.city,
                    town: this.town,
                    building: this.building,
                    intended_use_radio: this.intended_use_radio,
                    intended_use_text: this.intended_use_text
                }
                this.$_setUserInfo(UserInfo)
                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === val);
                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            },
            validation(){

            }
        }
    }
</script>

<style scoped>
    h2{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
    }
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .title {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        margin: 3% 0 1% 5%;
        padding: 0;
    }
    .main {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        background-color: #f4f4f4;
        text-align: left;
        border-radius: 15px;
        margin: 5%;
        padding: 5%;
    }
    .input{
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 98%;
        padding: 1%;
    }
    .postal_code{
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 65%;
        margin: 2% 2% 2% 0;
    }
    .form {
        text-align: left;
        margin: 0 0 10% 0;
    }
    .form_name {
        margin: 1% 0 1% 0
    }
    .check_button {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        width: 100%;
        margin: 5% 0 0 0;
        padding: 3%;
    }
    .gender_select{
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }
    .birth_day{
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }
    .pref_select {
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }
    .address_search {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: white;
        background-color: black;
        margin: 2% 0 1% 0;
    }
    .explanation{
        display: grid;
        grid-template-rows: 1fr 100px;
        margin: 3% 0;
    }
    .textarea{
        color: black;
        background-color: white;
        border: solid white 1px;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        height: 100px;
        margin: 0 0 2% 0;
    }
    .validate {
        color: red;
    }
    .warning {
        color: #9e9e9e;
    }
</style>