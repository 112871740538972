<!--
  Title        : 意気込み（九町連合会）
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>

        <img class="banner" src="/img/banner/百舌鳥連合バナーベース.png">

        <div class="main">
            <div v-for="(data, index) in res" :key="index">
                <div class="introduction" v-if="index % 2 === 1">
                    <div class="right">
                        <div class="town_info">
                            <img class="town_icon" :src="data.town_icon">
                            <div class="town_name">{{ data.town_name }}</div>
                        </div>
                        <div class="name">
                            名前：{{ data.leader_name }}
                        </div>
                        <div class="ikigomi">
                            意気込み<br>
                            {{ data.ikigomi }}
                        </div>
                    </div>
                    <div class="left">
                        <div class="town_leader">
                            <viewer class="viewer">
                                <img id="img" :src="data.leader_img">
                            </viewer>
                        </div>
                    </div>
                    <div class="highlight">
                        {{ data.highlight }}
                    </div>
                </div>
                <div class="introduction" v-else>
                    <div class="left">
                        <div class="town_leader">
                            <viewer class="viewer">
                                <img id="img" :src="data.leader_img">
                            </viewer>
                        </div>
                    </div>
                    <div class="right">
                        <div class="town_info">
                            <img class="town_icon" :src="data.town_icon">
                            <div class="town_name">{{ data.town_name }}</div>
                        </div>
                        <div class="name">
                            名前：{{ data.leader_name }}
                        </div>
                        <div class="ikigomi">
                            意気込み<br>
                            {{ data.ikigomi }}
                        </div>
                    </div>
                    <div class="highlight">
                        {{ data.highlight }}
                    </div>
                </div>
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    export default {
        name: "Info",
        data() {
            return{
                res: []
            }
        },
        created() {
            let self = this;
            let getResultAPI = this.$_mixAPI_getFutonInfo()
            getResultAPI.then(function(val){
                self.res = val
            }).catch((err)=>{   //通信エラー時
                console.log(err);
                //self.alertErrorVisible = true   //エラーダイアログ表示
            }).finally(()=>{
                self.alertLoadingVisible = false    //Loadingダイアログを非表示
            });
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .banner {
        color: white;
        width: 100%;
    }
    .main {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: white;
        border-radius: 10px;
        width: 92%;
        margin: 2%;
        padding: 2%;
    }
    #img {
        width: 100%;
        height: 100%;
    }
    .introduction {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        border-bottom: solid 3px black;
        padding: 3% 0 0;
    }
    .left {
        width: 160px;
        height: 160px;
        padding: 1% 1% 3%;
    }
    .right {
        font-size: 16px;
        text-align: left;
        width: calc(98% - 2% - 162px);
        padding: 0 2px 3% 2%;
    }
    .town_leader {
        height: 100%;
    }
    .town_info {
        border-bottom: solid 1px black;
        display: flex;
        flex-wrap: wrap;
        height: 30px;
        margin-bottom: 3px;
    }
    .town_icon {
        width: 30px;
        height: 30px;
    }
    .town_name {
        font-size: 20px;
        width: calc(100% - 10px - 30px);
        margin: 2px 0 0 10px;
    }
    .name {
        font-size: 16px;
        border-bottom: solid 1px black;
        height: 20px;
        padding-bottom: 2px;
        margin-bottom: 3px;
    }
    .ikigomi {
        font-size: 15px;
        white-space: pre-line;
        word-break: break-all;
    }
    .highlight {
        font-size: 15px;
        border-top: solid 1px black;
        text-align: left;
        white-space: pre-line;
        word-break: break-all;
        padding: 2% 2px 3% 2%;
    }
</style>