<!--
  Title        : 機能説明
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
    </v-ons-page>
</template>

<script>
    export default {
        name: "Description",
        methods: {
            PageTransition(val){
                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === val);

                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            }
        },
        beforeCreate() {
            console.log("beforeCreate");
        },
        created() {
            console.log("created");
        },
        beforeMount() {
            console.log("beforeMount");
        },
        mounted() {
            console.log("mounted");
        },
    }
</script>

<style scoped>
    .background {
        background-color: #fdfbfb;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .outer{
        width: 100%;
    }
</style>