<!--
  Title        : 日程表
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div v-cloak>
            <ul class="tabs">
                <li class="tab" @click="change(1)" :class="{'active' : isActive === 1}">祭礼日程</li>
                <li class="tab" @click="change(2)" :class="{'active' : isActive === 2}">行事日程</li>
            </ul>
            <ul>
                <li v-if="isActive === 1">
                    <!--祭礼日程-->
                    <div class="calendar">
                        <img src="img/banner/令和６年祭礼日程.png" style="width: 100%">
                        <div v-show="false">
                            <h3 class="attention">令和５年度の祭礼は<br>日程がわかり次第追加していきます。</h3>
                        </div>
                        <div v-for="(month, month_i) in fesRes" :key="month_i">
                            <table class="schedule">
                                <tbody>
                                <tr>
                                    <td class="td">月</td>
                                    <td class="td">日付</td>
                                    <td class="td">市町名</td>
                                    <td class="td">地区名</td>
                                    <td class="td">行事内容</td>
                                </tr>
                                <template>
                                    <tr v-for="(festival, festival_i) in month.schedule" :key="festival_i">
                                        <td class="month" :rowspan="month.town_count" v-show="festival_i === 0">{{ month.month }}月</td>
                                        <td class="day" :rowspan="festival.day_row" v-if="festival.day_row_flg">
                                            {{ festival.day }}日（<span :class="{'blue': festival.start_week === '土', 'red': festival.start_week === '日'}">{{ festival.start_week }}</span>）
                                        </td>
                                        <td class="town_name" :rowspan="festival.city_row" v-if="festival.city_row_flg">{{ festival.city_name }}</td>
                                        <td class="festival_name">{{ festival.festival_name }}</td>
                                        <td class="event_detail" :rowspan="festival.event_row" v-if="festival.event_row_flg">{{ festival.event_detail }}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>

                <li v-if="isActive === 2">
                    <!--行事日程-->
                    <div class="calendar">
                        <img src="img/banner/令和６年行事日程.png" style="width: 100%">
                        <div v-show="false">
                            <h3 class="attention">令和５年度の行事は<br>日程がわかり次第追加していきます。</h3>
                        </div>
                        <div v-for="(value, number) in res" :key="number">
                            <table class="schedule">
                                <tbody>
                                <tr>
                                    <td class="td">月</td>
                                    <td class="td">日付</td>
                                    <td class="td">地区名</td>
                                    <td class="td">町名</td>
                                    <td class="td">行事内容</td>
                                </tr>
                                <template>
                                    <tr v-for="(schedule, index) in value.schedule" :key="index">
                                        <td class="month" :rowspan="value.month_count" v-show="index === 0">{{ value.month }}月</td>
                                        <td class="day" :rowspan="schedule.day_row" v-if="schedule.day_row_flg">
                                            {{ schedule.day }}日（<span :class="{'blue': schedule.start_week === '土', 'red': schedule.start_week === '日'}">{{ schedule.start_week }}</span>）
                                        </td>
                                        <td class="festival_name" :rowspan="schedule.festival_row" v-if="schedule.festival_row_flg">{{ schedule.festival_name }}</td>
                                        <td class="town_name">{{ schedule.town_name }}</td>
                                        <td class="event_detail" :rowspan="schedule.event_row" v-if="schedule.event_row_flg">{{ schedule.event_detail }}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "Schedule",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar
        },
        data() {
            return {
                res: [],
                fesRes: [],
                scheduleDetail: [],
                festival_type: this.$_festivalType(),
                isActive: 1,
                alert: false,
            }
        },
        mounted() {
            let self = this
            let apiVal = {
                festival_type: this.festival_type
            }
            //祭礼日程
            let fesSchedule = this.$_mixAPI_getFestivalSchedule(apiVal)
            fesSchedule.then(function(val){
                self.fesRes = val
            })
            //行事日程
            let schedule = this.$_mixAPI_getSchedule(apiVal)
            schedule.then(function(val){
                self.res = val
            })
        },
        methods: {
            change: function(num){
                this.isActive = num
            }
        }
    }
</script>

<style scoped>
    [v-cloak] {
        display: none;
    }
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .schedule {
        width: 100%;
        color: #fdfbfb;
        border: 2px #484848 solid;
        border-collapse: collapse;
        margin: auto;
    }
    .schedule td {
        border: 2px #484848 solid;
    }
    .td {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #b99200;
        font-size: 80%;
    }
    .month {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: white;
        color: black;
        font-size: 80%;
        width: 10%;
    }
    .day {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 80%;
        width: 10%;
    }
    .town_name {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 80%;
        width: 24%;
    }
    .festival_name {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 80%;
        width: 27%;
    }
    .event_detail {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 80%;
        width: 29%;
    }
    .blue{
        color: #00CBFF;
    }
    .red{
        color: red;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 0 0 4% 0;
        font-size: 95%;
    }
    h2 span {
        border-bottom: #b58012 2px solid;
        padding: 0 5% 2% 5%;
    }
    .tabs {
        position: fixed;
        display: flex;
        list-style-type: none;
        width: 100%;
        height: 50px;
        padding: 0;
    }
    .tab{
        width: 100%;
        padding: 3% 0;
        color: white;
        background-color: gray;
        font-size: 100%;
        border: 1px solid #000;
        border-radius: 5px 5px 0 0;
    }
    .active {
        color: black;
        background-color: white;
        border-bottom: 2px solid #000;
    }
    .calendar {
        padding: 50px 0 0;
    }
    .attention {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: white;
        margin: 10% 0;
    }
</style>