<!--
  Title        : 新着情報
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>

        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div class="body">
            <h2 class="new_arrivals_title">
                <span>新着情報</span>
            </h2>
            <div class="new_arrivals_contents">
                <ul v-for="(value, index) in newArrivalRes.new_arrival" :key="index">
                    <li>
                        <span class="article_date">
                            {{ value.publication_date }}掲載
                        </span>
                        <br>
                        <span class="update_details">
                            {{ value.information }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "NewArrivals",
        props: ['toggleMenu'],
        components: {
            BottomToolbar,
            Toolbar,
        },
        data() {
            return {
                newArrivalRes: [],
            }
        },
        created: function() {
            let self = this
            let newArrivals = this.$_mixAPI_Notifications()
            newArrivals.then(function(val){
                self.newArrivalRes = val
            })
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 0 0 4% 0;
        font-size: 95%;
    }
    h2 span {
        border-bottom: #b58012 2px solid;
        padding: 0 5% 2% 5%;
    }
    .body {
        margin: 1% 1% 3% 1%;
        background: rgba(255, 255, 255, 0.9);
    }
    .new_arrivals_title{
        font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0 0 3% 0;
        padding: 3% 0 3% 0;
    }
    .new_arrivals_contents{
        text-align: left;
        margin: 0 3% 0 3%
    }
    .update_details {
        border-bottom: rgba(0, 0, 0, 0.5) 1px solid;
    }
</style>