<!--
  Title        : 問い合わせ内容確認
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>
        <div class="background"></div>

        <div style="color: white;text-align: left">
            <h2 class="title">だんじり総合情報アプリ「地車」</h2>
            <div class="title">お問い合わせ確認</div>
        </div>

        <div class="description">
            以下の各項目に不備がなければ、送信ボタンを押してください。<br><br>
            祭礼・曳行コース・入魂式等の時間のご質問はお控えください。<br><br>
            ※当サイトは個人サイトであり、祭礼団体には所属しておりません。<br>
            なお、祭礼・入魂式等のご質問に関して<br>
            お返事を差し上げられない場合がありますので、ご了承ください。
        </div>

        <div class="main">
            <div>
                <div class="form">
                    <div class="form_name">
                        <div>お問い合わせ内容</div>
                        <div>{{ intended_use }}</div>
                        <div>{{ intended_use_text }}</div>
                    </div>
                </div>

                <div class="form">
                    <div class="form_name">
                        <div>氏名</div>
                        <div>{{ name }}</div>
                    </div>
                </div>

                <div class="form">
                    <div class="form_name">
                        <div>メールアドレス</div>
                        <div>{{ email }}</div>
                    </div>
                </div>

                <v-ons-button class="check_button" @click="PageTransition('inquiryComplete')">送信</v-ons-button>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "InquiryConfirmation",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data() {
            return {
                name: this.$_inquiry().name,
                email: this.$_inquiry().email,
                intended_use: this.$_inquiry().intended_use,
                intended_use_text: this.$_inquiry().intended_use_text,
            }
        },
        methods: {
            PageTransition(val){

                let apiVal = {
                    email: this.email,
                    name: this.name,
                    intended_use: this.intended_use,
                    intended_use_text: this.intended_use_text ?? ""
                }

                let registrationInquiry = this.$_mixAPI_registrationInquiry(apiVal)
                registrationInquiry.then(function(val){
                    console.log(val)
                });

                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === val);
                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            },
        }
    }
</script>

<style scoped>
    h2{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
    }
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .title {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        margin: 3% 0 1% 5%;
        padding: 0;
    }
    .main{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        background-color: #f4f4f4;
        text-align: left;
        border-radius: 15px;
        margin: 5%;
        padding: 5%;
    }
    .form {
        text-align: left;
        margin: 0 0 10%;
    }
    .form_name {
        margin: 1% 0;
    }
    .description{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        border: 1px solid #000000;
        margin: 5%;
        padding: 2%;
    }
    .check_button {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        width: 100%;
        margin: 0;
        padding: 3%;
    }
</style>