<!--
  Title        : コース表
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
  <v-ons-page>
    <div class="background"></div>
    <div class="main">
      <div>
        <div v-for="(course,index) in res" :key="index">
          <input :id="'acd-check'+index" class="acd-check" type="checkbox" checked>
          <label class="acd-label" :for="'acd-check'+index">{{course.event_date}}<ons-icon id="icon" size="20px" icon="fa-sort-down"></ons-icon></label>
          <div class="acd-content">
            <viewer class="viewer">
              <img :src="course.img_path" style="width: 100%">
            </viewer>
          </div>
        </div>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import VueViewer from "v-viewer"
import Vue from "vue"

Vue.use(VueViewer, {
  defaultOptions: {
    inline: false,
    button: true,
    navbar: false,
    title: false,
    toolbar: false,
    tooltip: false,
    movable: true,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: false,
    fullscreen: false,
    keyboard: false,
    toggleOnDblclick: false
  }
})

export default {
  name: "DanjiriCourse",
  data() {
    return {
      res: []
    }
  },
  created: function() {
    this.alertLoadingVisible = true   //Loadingダイアログ表示開始
    let self = this   //thenの中からdataを操作するため
    let apiVal = {
      festival_id: this.$_festivalIDS(),
      town_id: this.$_TownData().town_id
    }
    let getResultAPI = this.$_mixAPI_postCourseData(apiVal)
    getResultAPI.then(function(val){
      self.res = val
    }).catch((err)=>{   //通信エラー時
      if (err.response.status != 404) {
        self.alertErrorVisible = true   //エラーダイアログ表示
      }
    }).finally(()=>{
      self.alertLoadingVisible = false    //Loadingダイアログを非表示
    });
  }
}
</script>

<style scoped>
p {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 60px auto 40px;
}
table {
  margin: 20px auto;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}
.tbl-r02 th {
  background: #000000;
  border: solid 1px #ccc;
  color: #fff;
  padding: 10px;
}
.tbl-r02 td {
  border: solid 1px #000;
  padding: 10px;
  background-color: white;
  opacity: 0.9;
}
.last td:last-child {
  border-bottom: solid 1px #ccc;
  width: 100%;
}
.tbl-r02 th,
.tbl-r02 td {
  border-bottom: solid 1px;
  font-weight: bold;
}
.background {
  background-image: url("/img/bg_sayagata.jpg");
  opacity: 1;
}
.main {
  margin: auto;
  width: 100%;
}
.acd-check {
  display: none;
}
.acd-label {
  background: #333;
  color: #ffde65;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  margin: 0 auto;
  font-size: 20px;
  font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
  position: relative;
}
.acd-content {
  height: 0;
  opacity: 0;
  transition: .5s;
  visibility: hidden;
}
.acd-check:checked + .acd-label + .acd-content {
  height: auto;
  opacity: 1;
  visibility: visible;
}
#icon{
  top: -2px;
  margin-left: 20px;
  position: relative;
}
.viewer-move {
  pointer-events: none !important;
}
</style>
