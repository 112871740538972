<!--
  Title        : 問い合わせ
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>
        <div class="background"></div>

        <div style="color: white;text-align: left">
            <h2 class="title">祭礼総合情報アプリ<br>「地車＆布団太鼓」</h2>
            <div class="title">お問い合わせ</div>
        </div>

        <div class="description">
            以下の各項目に入力して、確認ボタンを押してください。<br><br>
            祭礼・曳行コース・入魂式等の時間のご質問はお控えください。<br><br>
            ※当サイトは個人サイトであり、祭礼団体には所属しておりません。<br>
            なお、祭礼・入魂式等のご質問に関して<br>
            お返事を差し上げられない場合がありますので、ご了承ください。
        </div>

        <div class="main">
            <div>
                <div class="explanation">
                    <div class="form_name">
                        お問い合わせ内容
                    </div>
                    <div>
                        <input type="radio" id="option1" value="アプリ加入について" v-model="intended_use_radio"/>
                        <label for="option1">アプリ加入について</label>
                        <br>
                        <input type="radio" id="option2" value="アプリに関する質問" v-model="intended_use_radio"/>
                        <label for="option2">アプリに関する質問</label>
                        <br>
                        <input type="radio" id="option3" value="その他" v-model="intended_use_radio"/>
                        <label for="option3">その他（自由記載）</label>
                    </div>
                    <textarea class="textarea" id="text_area" v-model="intended_use_text"></textarea>
                    <div class="validate" v-show="intended_use_text_validate">
                        ※問い合わせ内容を入力してください
                    </div>
                </div>

                <div class="form">
                    <div class="form_name">
                        氏名
                    </div>
                    <div class="input_form">
                        <v-ons-input class="input" type="text" name="name" v-model="name"/>
                    </div>
                    <div class="validate" v-show="name_validate">
                        ※氏名を入力してください
                    </div>
                </div>

                <div class="form">
                    <div class="form_name">
                        メールアドレス
                    </div>
                    <div class="input_form">
                        <v-ons-input class="input" type="email" name="email" v-model="email"/>
                    </div>
                    <div class="validate" v-show="email_validate">
                        ※メールアドレスを入力してください
                    </div>
                </div>

                <v-ons-button class="check_button" @click="PageTransition('inquiryConfirmation')">確認</v-ons-button>
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "Inquiry",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data() {
            return {
                name: '',
                email: '',

                showChild: true,

                name_validate: false,
                email_validate: false,
                intended_use_text_validate: false,

                intended_use_radio: '',
                intended_use_text: '',
            }
        },
        methods: {
            PageTransition(val){
                if(this.intended_use_text == ''){
                    this.intended_use_text_validate = true
                    return
                }
                if(this.name == ''){
                    this.name_validate = true
                    return
                }
                if(this.email == ''){
                    this.email_validate = true
                    return
                }
                if(this.email){
                    //メールアドレスとして判定される文字列と記号の組み合わせを定数化
                    const reg = new RegExp(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/);
                    //指定した組み合わせになっていなかった場合判定を返す。
                    if(reg.test(this.email) == false){
                        this.email_validate = true
                        return
                    }
                }
                let Inquiry = {
                    email: this.email,
                    name: this.name,
                    intended_use: this.intended_use_radio,
                    intended_use_text: this.intended_use_text ?? ""
                }
                this.$_setInquiry(Inquiry)

                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === val);
                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            },
        }
    }
</script>

<style scoped>
    h2{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
    }
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .title {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        margin: 3% 0 1% 5%;
        padding: 0;
    }
    .main{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        background-color: #f4f4f4;
        text-align: left;
        border-radius: 15px;
        margin: 5%;
        padding: 1% 5% 5%;
    }
    .form {
        text-align: left;
        margin: 0 0 10%;
    }
    .form_name {
        margin: 1% 0;
    }
    .input{
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 98%;
        padding: 1%;
    }
    .explanation{
        display: grid;
        grid-template-rows: 1fr 70px;
        margin: 3% 0;
    }
    .textarea{
        color: black;
        background-color: white;
        border: solid white 1px;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        height: 100px;
        margin: 3% 0 2%;
    }
    .description{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        border: 1px solid #000000;
        margin: 5%;
        padding: 2%;
    }
    .check_button {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        width: 100%;
        margin: 5% 0 0;
        padding: 3%;
    }
    .validate {
        color: red;
    }
</style>