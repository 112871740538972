// import Vue from 'vue';
import Http from '../common/axios'
//
const headers_gps = {
    'x-api-key': 'EfT96JlHKx8Rd2c45gsIG5sceq2HgMuT7tYSCRuJ'
}

var instance_gps = null;

export const mixAPI_GPS = {
    data () {
        return {
            instance_gps: instance_gps
        }
    },
    created() {
        let jConfig = this.mixCF_config;
        let api_url = jConfig[0].api_gps_url; //初configから取得
        this.instance_gps = new Http(api_url, headers_gps);
    },
    methods: {
        async $_mixAPI_GPS_getLatLng(val){
            const res = await this.instance_gps.get('',val)
            return res.body
        }
    }
}