<!--
  Title        : タブバー（布団太鼓専用）
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-tabbar id="tab" position="top"
                  :swipeable="true"
                  :tabs="tabs"
                  :visible="true"
                  :index.sync="activeIndex"
                  :tab-border="true"
    >
    </v-ons-tabbar>
</template>

<script>
    export default {
        name: "FutonTabbar",
        data() {
            return {
                activeIndex: this.$_mixPC_ActiveTabIndex(),
                tabs: this.$_mixPC_FutonTabmenu_init()
            }
        },
        mounted() {
            // Create a new stylesheet in the document
            let styleSheet = document.createElement('style');
            document.head.appendChild(styleSheet);
            // Use insertRule() to add a new rule to the stylesheet
            styleSheet.sheet.insertRule('.tabbar__border { width: 33.3% !important; }', 0);
        }
    }
</script>

<style>

</style>