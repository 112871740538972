<!--
  Title        : 商品,個人情報修正画面
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div style="color: white;text-align: left">
            <h2 class="title">注文内容</h2>
            <div class="title">注文内容にお間違いないかご確認ください。</div>
        </div>

        <div class="main">
            <div class="info">
                <div>商品名</div>
                <div>{{ goods_name }}</div>
            </div>
            <div class="info" v-show="goods_name == 'タオル'">
                <div>生地</div>
                <img :src="selected_towel_material_img">
            </div>
            <div class="info" v-show="goods_name == 'タスキ' ||goods_name == '巾着' || goods_name == '法被' || goods_name == '鯉口シャツ' || goods_name == 'チョッキ'">
                <div>生地</div>
                <img :src="selected_material_img">
            </div>
            <div class="info" v-show="goods_name == 'タオル'">
                <div>柄</div>
                <img :src="selected_pattern_img">
            </div>
            <div class="info" v-show="goods_name == 'タスキ'">
                <div>加工方法</div>
                <img :src="selected_machining_img">
            </div>
            <div class="info" v-show="goods_name == '団扇'">
                <div>種類</div>
                <img :src="selected_fan_img">
            </div>
            <div class="info" v-show="goods_name == '団扇'">
                <div>{{ type }}</div>
            </div>
            <div class="info" v-show="goods_name == 'タスキ房'">
                <div>色数</div>
                <div>{{ color }}</div>
            </div>
            <div class="info" v-show="goods_name == '法被' || goods_name == '鯉口シャツ' || goods_name == 'チョッキ' || goods_name == 'ロンパース' || goods_name == 'タスキ房' || goods_name == '団扇'">
                <div>サイズ</div>
                <div>{{ size }}</div>
            </div>
            <div class="info">
                <div>数量</div>
                <div>{{ volume }}</div>
            </div>
            <div class="info">
                <div>金額(税抜)</div>
                <div>{{ amount }}円</div>
            </div>
        </div>

        <div class="main">
            <div class="info">
                <div>氏名</div>
                <div>{{ name }}</div>
            </div>
            <div class="info">
                <div>性別</div>
                <div>{{ gender }}</div>
            </div>
            <div class="info">
                <div>生年月日</div>
                <div>{{ year }}年{{ month }}月{{ day }}日</div>
            </div>
            <div class="info">
                <div>郵便番号</div>
                <div>{{ postal_code }}</div>
            </div>
            <div class="info">
                <div>住所</div>
                <div>{{ prefecture }}{{ town }}{{ city }}{{ building }}</div>
            </div>
            <div class="info">
                <div>メールアドレス</div>
                <div>{{ email }}</div>
            </div>
            <div class="info">
                <div>使用目的</div>
                <div>{{ intended_use_radio }}</div>
                <div>{{ intended_use }}</div>
            </div>

            <div>
                <v-ons-button class="decision" @click="OrderComplete()">注文確定</v-ons-button>
                <!--                <v-ons-button class="revision" @click="PageTransition('revision')">修正</v-ons-button>-->
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../../Common/Toolbar";

    export default {
        name: "revision",
        props: ['toggleMenu'],
        components: {
            Toolbar,
        },
        data(){
            return{
                goods_name: this.$_GoodsDetail().goods_name,
                volume: this.$_GoodsDetail().volume,
                amount: this.$_GoodsDetail().amount,
                color: this.$_GoodsDetail().color,
                size: this.$_GoodsDetail().size,
                selected_towel_material: this.$_GoodsDetail().selected_towel_material,
                selected_towel_material_img: this.$_GoodsDetail().selected_towel_material_img,
                selected_material: this.$_GoodsDetail().selected_material,
                selected_material_img: this.$_GoodsDetail().selected_material_img,
                selected_pattern: this.$_GoodsDetail().selected_pattern,
                selected_pattern_img: this.$_GoodsDetail().selected_pattern_img,
                selected_machining: this.$_GoodsDetail().selected_machining,
                selected_machining_img: this.$_GoodsDetail().selected_machining_img,
                selected_fan: this.$_GoodsDetail().selected_fan,
                selected_fan_img: this.$_GoodsDetail().selected_fan_img,

                name: this.$_UserInfo().name,
                gender: this.$_UserInfo().gender,
                email: this.$_UserInfo().email,
                year: this.$_UserInfo().year,
                month:this.$_UserInfo().month,
                day: this.$_UserInfo().day,
                postal_code: this.$_UserInfo().postal_code,
                prefecture: this.$_UserInfo().prefecture,
                city: this.$_UserInfo().city,
                town: this.$_UserInfo().town,
                building: this.$_UserInfo().building,
                intended_use_radio: this.$_UserInfo().intended_use_radio,
                intended_use: this.$_UserInfo().intended_use,
            }
        },
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
</style>