<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar v-if="showChild" :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div style="color: white;text-align: left">
            <h2 class="title">お問い合わせが完了しました。</h2>
        </div>

        <div class="main">
            お問い合わせいただき、ありがとうございます。
            <br>
            担当者よりご連絡をさせていただきます。
            <div>
                <v-ons-button class="button" @click="PageTransition('main')">トップページに戻る</v-ons-button>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "InquiryComplete",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data(){
            return{
                showChild: true,
            }
        },
        methods:{
            PageTransition(val){

                let Inquiry = {}
                this.$_setInquiry(Inquiry)

                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === val);
                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            },
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .title {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        margin: 3% 0 1% 5%;
        padding: 0;
    }
    .main {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        background-color: #f4f4f4;
        text-align: left;
        border-radius: 15px;
        margin: 5%;
        padding: 5%;
    }
    .button {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        background-color: #9e9e9e;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        position: center;
        text-align: center;
        width: 90%;
        margin: 5%;
        padding: 3%;
    }
</style>