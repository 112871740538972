<!--
  Title        : スポンサー一覧
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <img src="img/banner/sponsor.jpg" style="width: 100%;">

        <div class="sponsor">
            <div class="grid" id="large_amount">
                <div class="fixed" v-for="(sponsor,index) in large" :key="index">
                    <img id="sponsor_large" :src="sponsor.sponsor_img" @click="push(sponsor.sponsor_id)" />
                    <div id="company_name">
                        {{ sponsor.festival_name }}<br v-show="sponsor.festival_id">
                        {{ sponsor.company_name }}
                    </div>
                </div>
            </div>
            <div class="grid" id="small_amount">
                <div class="fixed" v-for="(sponsor,index) in small" :key="index">
                    <img id="sponsor_small" :src="sponsor.sponsor_img" @click="push(sponsor.sponsor_id)" />
                    <div id="company_name">
                        {{ sponsor.festival_name }}<br>
                        {{ sponsor.company_name }}
                    </div>
                </div>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "AllSponsor",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data() {
            return {
                large: [],
                small: []
            }
        },
        created() {
            let self = this
            let getResultAPI = self.$_mixAPI_getAllSponsor()
            getResultAPI.then(function(val){
                self.large = val.large
                self.small = val.small
            }).catch((err)=>{
                if (err.response.status != 404){
                    self.alertErrorVisible = true
                }
            }).finally(()=>{
                self.alertLoadingVisible = false
            });
        },
        methods: {
            push(Val){
                return(
                    this.$_setPage('AllSponsor'),
                    this.$_setSponsor_id(Val),
                    //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                    this.$_SetmixPC_CurrentPage_Key('sponsorDetailTab')
                );
            },
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .sponsor{
        color: white;
        background: #fffbea;
        border-radius: 20px;
        width: 90%;
        margin: 5% 2%;
        padding: 2% 3% 3%;
    }
    #large_amount{
        display: grid;
        /*grid-template-columns: 1fr 1fr;*/
        grid-column-gap: 2%;
    }
    #small_amount {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2%;
    }
    .fixed{
        display: flex; /* 追加 */
        flex-direction: column; /* 追加 */
        align-items: center; /* 追加 */
        text-align: center;
        position: relative;
        margin: 2% 0;
    }
    #sponsor_large{
        vertical-align:top;
        border: solid 1px black;
        width: 100%;
        height: 180px;
        margin: 0;
    }
    #sponsor_small{
        vertical-align:top;
        border: solid 1px black;
        width: 100%;
        height: 120px;
        margin: 0;
    }
    #company_name{
        font-family: "Hiragino Sans", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        word-wrap:break-word;
        white-space:pre-wrap;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: url("/img/parts/金色屏風-背景.jpg");
        color: black;
        border: solid 1px black;
        font-size: 16px;
        width: 100%;
        height: auto;
        margin: 0;
    }
</style>