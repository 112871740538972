import Vue from 'vue'
import App from './App.vue'

//Vue Swiper
import 'swiper/dist/css/swiper.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

//Vue Carousel
import VueCarousel from 'vue-carousel'
Vue.use(VueCarousel)

//nakano�ǉ�
import InfiniteLoading from 'vue-infinite-loading'
Vue.use(InfiniteLoading)
Vue.config.productionTip = false

//***** OnsenUI Insert S ******
// Webpack CSS import
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

// JS import
import VueOnsen from 'vue-onsenui'; // This already imports 'onsenui'

Vue.use(VueOnsen);

//***** OnsenUI Insert E ******

//***** Google Map ***********
// Vue.config.productionTip = false
// import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyC-0iJPA3FKsAS-dVzwE_-Foe-XR-WEyOQ',
//     libraries: 'places',
//     region: 'JP',
//     language: 'ja'
//   }
// });

//***** Mixin Insert S *****
import { mixConfig } from '@/mixins/config_mixin';
import { mixPageController }  from '@/mixins/pages_controller_mixins';
import { mixGlobalData }  from '@/mixins/global_data';
import { mixAPI } from '@/mixins/api_mixin';
import { mixAPI_GPS } from "@/mixins/api_gps_mixin";

Vue.mixin(mixConfig);
Vue.mixin(mixPageController);
Vue.mixin(mixGlobalData);
Vue.mixin(mixAPI);
Vue.mixin(mixAPI_GPS);
//***** Mixin Insert E *****

new Vue({
  render: h => h(App),
}).$mount('#app')
