import Vue from 'vue'
// import App from './App.vue'
var FestivalData = {
    festival_id:0,
    festival_latitude:0,
    festival_longitude:0,
    festival_banner: '',
    favorites:false,
    Sponsors:[]
}
var TownData = {
    town_id:0,
    purchase_date:'',
    carpenter:'',
    sculpture:'',
    remarks:'',
    storehouse_latitude:0,
    storehouse_longitude:0,
    favorites:false,
    town_icon:'',
    town_img:'',
    hut_img:'',
    Sponsors:[],
    // Enthusiasms:[],
    News:[],
    GPS:[],
}
//販売管理データ 商品詳細
var GoodsDetail = {

}
//販売管理データ お客様情報
var UserInfo = {
    name: '',
    gender: '',
    email: '',
    year: '',
    month:'',
    day: '',
    postal_code:'',
    prefecture: '',
    city: '',
    town: '',
    building: '',
    intended_use_radio: '',
    intended_use_text: ''
}

//問い合わせデータ
var Inquiry = {
    email: '',
    name: '',
    intended_use: '',
    intended_use_text: ''
}

let GlobalData = new Vue({
    data:{
        $uuid: '', //UUID
        $deviceToken: '',   //DeviceToken
        $festivalType: 1,
        $festivalIDS: 0,   //選択している祭りID管理
        $TownData: TownData, //町会基本データ
        $FestivalData: FestivalData, //祭り基本データ
        $AllTownData: [],
        $Town_id: 0,
        $News_id: 0,     //お知らせID
        $password: '',
        $Sponsor_id: 0,      //スポンサーID
        $CityId: 0,
        $Terminal: 'iPhone',

        //googlemap初期位置
        $FestivalLatitude: 0,
        $FestivalLongitude: 0,

        //管理者用データ
        $AdTownID: 0,
        $AdFestivalID: 0,
        $PlaceName: '',

        //通知
        $FestivalNotificationId: 0,
        $TownNotificationId: 0,
        $UnreadCountFlg: false,
        $UnreadNewsId: [],
        $UnreadNenbanCountFlg: false,
        $UnreadNenbanNewsId: [],

        //スポンサー
        $CategoryId: 0,

        //アプリバージョン
        $ApplicationVersion: 1,
        $ApplicationVersionDB: 4,

        $Page: '',

        //だんじり販売管理データ
        $GoodsDetail: GoodsDetail,
        $UserInfo: UserInfo,

        //問い合わせデータ
        $Inquiry: Inquiry
    }
});

export const mixGlobalData = {
    methods: {
        //各コンポーネント内の<script>タグ内でグローバル変数を参照するための関数
        //-- 選択している都道府県、祭りID管理 --
        $_festivalType(){
            return GlobalData.$data.$festivalType
        },
        $_setfestivalType(val) {
            GlobalData.$data.$festivalType = val
        },
        $_festivalIDS(){
            return GlobalData.$data.$festivalIDS
        },
        $_setfestivalIDS(val) {
            GlobalData.$data.$festivalIDS = val
        },
        $_FestivalData(){
            return GlobalData.$data.$FestivalData
        },
        $_setFestivalData(val) {
            GlobalData.$data.$FestivalData = val
        },
        $_TownData(){
            return GlobalData.$data.$TownData
        },
        $_setTownData(val) {
            GlobalData.$data.$TownData = val
        },
        $_AllTownData(){
            return GlobalData.$data.$AllTownData
        },
        $_setAllTownData(val) {
            GlobalData.$data.$AllTownData = val
        },
        $_uuid(){
            return GlobalData.$data.$uuid
        },
        $_setuuid(val) {
            GlobalData.$data.$uuid = val
        },
        $_deviceToken(){
            return GlobalData.$data.$deviceToken
        },
        $_setDeviceToken(val) {
            GlobalData.$data.$deviceToken = val
        },
        $_TownID(){
            return GlobalData.$data.$Town_id
        },
        $_setTownID(val) {
            GlobalData.$data.$Town_id = val
        },
        $_NewsID(){
            return GlobalData.$data.$News_id
        },
        $_setNewsID(val) {
            GlobalData.$data.$News_id = val
        },
        $_checkTownPassaord() {
            return GlobalData.$data.$password
        },
        $_Sponsor_id(){
            return GlobalData.$data.$Sponsor_id
        },
        $_setSponsor_id(val) {
            GlobalData.$data.$Sponsor_id = val
        },
        $_AdTownID(){
            return GlobalData.$data.$AdTownID
        },
        $_setAdTownID(val) {
            GlobalData.$data.$AdTownID = val
        },
        $_AdFestivalID(){
            return GlobalData.$data.$AdFestivalID
        },
        $_setAdFestivalID(val) {
            GlobalData.$data.$AdFestivalID = val
        },
        $_PlaceName() {
            return GlobalData.$data.$PlaceName
        },
        $_setPlaceName(val) {
            GlobalData.$data.$PlaceName = val
        },
        $_Terminal() {
            return GlobalData.$data.$Terminal
        },
        $_setTerminal(val) {
            GlobalData.$data.$Terminal = val
        },
        $_Page() {
            return GlobalData.$data.$Page
        },
        $_setPage(val) {
            return GlobalData.$data.$Page = val
        },
        $_FestivalNotificationID(){
            return GlobalData.$data.$FestivalNotificationId
        },
        $_setFestivalNotificationID(val){
            return GlobalData.$data.$FestivalNotificationId = val
        },
        $_TownNotificationID(){
            return GlobalData.$data.$TownNotificationId
        },
        $_setTownNotificationID(val){
            return GlobalData.$data.$TownNotificationId = val
        },
        $_CategoryID(){
            return GlobalData.$data.$CategoryId
        },
        $_setCategoryID(val){
            return GlobalData.$data.$CategoryId = val
        },
        $_CityID(){
            return GlobalData.$data.$CityId
        },
        $_setCityID(val){
            return GlobalData.$data.$CityId = val
        },
        $_ApplicationVersion(){
            return GlobalData.$data.$ApplicationVersion
        },
        $_setApplicationVersion(val){
            return GlobalData.$data.$ApplicationVersion = val
        },
        $_TabNumber(){
            return GlobalData.$data.$tab_number
        },
        $_setTabNumber(val){
            return GlobalData.$data.$tab_number = val
        },
        $_UnreadCountFlg(){
            return GlobalData.$data.$UnreadCountFlg
        },
        $_setUnreadCountFlg(val){
            return GlobalData.$data.$UnreadCountFlg = val
        },
        $_UnreadNewsID(){
            return GlobalData.$data.$UnreadNewsId
        },
        $_setUnreadNewsID(val){
            return GlobalData.$data.$UnreadNewsId = val
        },
        $_UnreadNenbanCountFlg(){
            return GlobalData.$data.$UnreadNenbanCountFlg
        },
        $_setUnreadNenbanCountFlg(val){
            return GlobalData.$data.$UnreadNenbanCountFlg = val
        },
        $_UnreadNenbanNewsID(){
            return GlobalData.$data.$UnreadNenbanNewsId
        },
        $_setUnreadNenbanNewsID(val){
            return GlobalData.$data.$UnreadNenbanNewsId = val
        },

        $_Swipe(){
            return GlobalData.$data.$swipe
        },
        $_setSwipe(val){
            return GlobalData.$data.$swipe = val
        },

        $_FestivalLatitude() {
            return GlobalData.$data.$FestivalLatitude
        },
        $_setFestivalLatitude(val) {
            return GlobalData.$data.$FestivalLatitude = val
        },
        $_FestivalLongitude() {
            return GlobalData.$data.$FestivalLongitude
        },
        $_setFestivalLongitude(val) {
            return GlobalData.$data.$FestivalLongitude = val
        },

        //販売管理 商品詳細
        $_GoodsDetail(){
            return GlobalData.$data.$GoodsDetail
        },
        $_setGoodsDetail(val) {
            GlobalData.$data.$GoodsDetail = val
        },
        //販売管理 お客様情報
        $_UserInfo(){
            return GlobalData.$data.$UserInfo
        },
        $_setUserInfo(val) {
            GlobalData.$data.$UserInfo = val
        },

        //問い合わせ内容
        $_inquiry(){
            return GlobalData.$data.$Inquiry
        },
        $_setInquiry(val){
            GlobalData.$data.$Inquiry = val
        }
    },
    computed: {
        //各コンポーネント内の<template>タグ内でグローバル変数を参照するための処置
        $festivalIDS: {
            get: function () { return GlobalData.$data.$festivalIDS },
            set: function (val) { GlobalData.$data.$festivalIDS = val }
        },
        $FestivalData: {
            get: function () { return GlobalData.$data.$FestivalData },
            set: function (val) { GlobalData.$data.$FestivalData = val }
        },
        $TownData: {
            get: function () { return GlobalData.$data.$TownData },
            set: function (val) { GlobalData.$data.$TownData = val }
        },
        $uuid: {
            get: function () { return GlobalData.$data.$uuid },
            set: function (val) { GlobalData.$data.$uuid = val }
        },
    }
}
