<!--
  Title        : タブメイン（布団太鼓専用）
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <Toolbar :action="toggleMenu"></Toolbar>
        <TabBar style="z-index:1;padding-top: 1px"/>
        <BottomToolbar></BottomToolbar>
    </v-ons-page>
</template>

<script>
    import Toolbar from '../Common/Toolbar'
    import TabBar from '@/components/Common/FutonTabbar';
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "FutonTab",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            TabBar,
            BottomToolbar
        },
    }
</script>

<style scoped>

</style>