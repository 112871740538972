<!--
  Title        : お知らせ編集
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
  <v-ons-page>
      <div class="background"></div>
      <Toolbar :action="toggleMenu"></Toolbar>
      <BottomToolbar></BottomToolbar>

      <div class="main">
          <div class="title">
              <div id="label">
                  <p>タイトル</p><p id="asta">*</p>
              </div>
              <v-ons-input id="textTitle" placeholder="タイトル" float
                           v-model="title" />
          </div>

          <div class="file">
              <div id="label">
                  <p>写真選択</p>
              </div>
              <div class="right">{{ this.filecnt }}件</div>
              <input type="file" id="file-btn1" style="display: none; background-color: red;" @change="selectedFile" ref="input_file" accept="image/*" multiple name="file-btn1" />
              <v-ons-button id="select" @click="fileClick">選択</v-ons-button>
          </div>

          <div class="img" v-if="img">
              <carousel :per-page="1" :pagination-size="5" :pagination-padding="5">
                  <slide v-for="(value, index) in img" :key=index>
                      <viewer class="viewer">
                          <img id="img" :src="value">
                      </viewer>
                  </slide>
              </carousel>
          </div>

          <p id="warning">
              ※写真を変更される際は再度写真を選択しなおしてください。
          </p>

          <div class="explanation">
              <p>本文</p>
              <textarea class="textarea" id="text_area" v-model="description"></textarea>
          </div>

          <div>
              <div id="label">
                  <p>削除パスワード(半角英数6～10文字)</p><p id="asta">*</p>
              </div>
              <v-ons-input type="password" id="textPass" placeholder="パスワード" float
                           v-model="hiddenPass" v-if="showPass == 'password'" />
              <v-ons-input type="text" id="textPass" placeholder="パスワード" float
                           v-model="hiddenPass" v-if="showPass == 'text'" />
              <label class="checkbox-inline">
                  <input type="checkbox" v-model="showPass" style="display: none" true-value="text" ref="input_icon" false-value="password"/>
                  <i class="fa fa-eye" title="表示" @click="iconClick" v-if="iconToggle"></i>
                  <i class="fa fa-eye-slash" title="非表示" @click="iconClick" v-else></i>
              </label>
          </div>
          <br/>
          <br/>
          <p id="warning">
              ※ご自身で写真を削除する場合に必要になりますので、
              お忘れにならないようご注意ください。
          </p>

          <v-ons-button id="submit" @click="SendButtonClick">更新</v-ons-button>

          <!------------------------->
          <!--◆ダイアログ表示部分◆-->
          <!------------------------->
          <!--エラーダイアログ-->
          <v-ons-alert-dialog modifier="rowfooter"
                              :visible.sync="alertErrorVisible">
              <span slot="title">エラー</span>
              <template slot="footer">
                  <v-ons-alert-dialog-button @click="alertErrorVisible = false">OK</v-ons-alert-dialog-button>
              </template>
          </v-ons-alert-dialog>

          <!--投稿確認ダイアログ-->
          <v-ons-alert-dialog modifier="rowfooter"
                              :visible.sync="alertVisible">
              <span slot="title">確認</span>
              投稿内容を変更します。よろしいですか。
              <template slot="footer">
                  <v-ons-alert-dialog-button @click="SendData">はい</v-ons-alert-dialog-button>
                  <v-ons-alert-dialog-button @click="alertVisible = false">いいえ</v-ons-alert-dialog-button>
              </template>
          </v-ons-alert-dialog>

          <!--成功ダイアログ-->
          <v-ons-alert-dialog modifier="rowfooter"
                              :visible.sync="alertSuccessVisible">
              変更しました。
              <template slot="footer">
                  <v-ons-alert-dialog-button @click="Post">OK</v-ons-alert-dialog-button>
              </template>
          </v-ons-alert-dialog>

          <!--ローディングダイアログ-->
          <v-ons-alert-dialog modifier="rowfooter"
                              :visible.sync="alertLoadingVisible">
              <Spinner message="投稿中...." />
          </v-ons-alert-dialog>

      </div>

      <BR /><BR /><BR />
  </v-ons-page>

</template>

<script>
import Toolbar from "../Common/Toolbar";
import BottomToolbar from "../Common/BottomToolbar";
import Spinner from "vue-simple-spinner";

export default {
    name: "NewsEdit",
    props: ['toggleMenu'],
    components: {
        Toolbar,
        BottomToolbar,
        Spinner
    },
    data() {
        return {
            name: "",
            title: "",
            avatar: [],
            filecnt: 0,
            alertErrorVisible: false,
            alertSuccessVisible: false,
            alertVisible: false,
            alertLoadingVisible: false,
            res: null,
            picToggle: true,
            iconToggle: true,
            hiddenPass: "",
            showPass: "password",
            festival_id: 0,
            town_id: 0,
            img: [],
            disclosure_range_flg: 1
        }
    },
    created: function(){
        console.log(this.$_Page())

        //戻るボタンクリックしたときに戻るタブをセット
        if(this.$_Page() == 'news'){
            this.$_SetmixPC_ActiveTabIndex(4)
        }
    },
    mounted: function(){
        let self = this

        let news = {
            news_id: this.$_NewsID()
        }
        let getNewsData = this.$_mixAPI_postNewsData(news)
        getNewsData.then(function(val){
            val.forEach((value) => {
                self.title = value.title
                self.description = value.description
                self.hiddenPass = value.password
                self.img = value.contents_path
                self.filecnt = value.contents_path.length
            })
        }).catch((err)=>{   //通信エラー時
            console.log(err);
            self.alertErrorVisible = true   //エラーダイアログ表示
        }).finally(()=>{
            self.alertLoadingVisible = false    //Loadingダイアログを非表示
        });
    },
    methods: {
        fileClick: function(){
            let self = this
            self.$refs.input_file.click();
        },
        iconClick: function(){
            let self = this
            self.$refs.input_icon.checked;
            if(self.iconToggle == true){
                self.iconToggle = false
            }else{
                self.iconToggle = true;
            }
        },
        selectedFile: async function(event) {
            const files = event.target.files || event.dataTransfer.files
            const fileLimit = 6;
            let self = this
            //ファイル件数をここで取得
            self.filecnt = files.length

            //枚数制限する場合は、ここでエラーポップアップを表示する。
            if(self.filecnt > fileLimit){
                self.res = "枚数がオーバーしています。";
                self.alertErrorVisible = true       //エラーダイアログ表示
                document.getElementById('file-btn1').value = "";
                self.filecnt = 0;
                return;
            }

            if(!self.filecnt){
                this.img = [];
                this.src = '';
            }

            for(let i = 0; i < self.filecnt; i++){
                if(self.picToggle === true){
                    //jpeg or png or heic【写真選択時】
                    if (files[i].type !== 'image/jpeg' && files[i].type !== 'image/png') {
                        self.res = "拡張子が対応していません。";
                        self.alertErrorVisible = true       //エラーダイアログ表示
                        document.getElementById('file-btn1').value = "";
                        self.filecnt = 0;
                        return;
                    }
                    var url = []
                    files.forEach(function(file){
                        url.push(URL.createObjectURL(file))
                    })
                    this.img = url
                }
                // ファイルが選択されていればBase64に変換する
                if (this.checkFile(files[i])){
                    self.avatar[i] = await this.getBase64(files[i])
                }
            }
        },
        getBase64: function(file){
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        },
        SendButtonClick: function(){
            let self = this
            /*◆入力チェック*/
            if(this.ErrCheck() == false){
                //エラーがあれば抜ける
                return;
            }
            //投稿確認ダイアログを表示
            self.alertVisible = true
        },
        ErrCheck: function(){
            let self = this
            this.res = '';

            var hissu=Array("textTitle","textPass");
            var hissu_nm = Array("タイトル","削除パスワード");
            var len=hissu.length;

            /*◆必須項目入力チェック*/
            //投稿者名が入力されていない場合
            //タイトルが入力されていない場合
            //削除パスワードが入力されていない場合
            for(var i = 0; i < len; i++){
                var obj = document.getElementById(hissu[i]);
                if(obj.type == "text" || obj.type == "textarea"){
                    //入力されていなければエラー
                    if(obj.value == ""){
                        self.res = hissu_nm[i]+"を入力してください。";
                        self.alertErrorVisible = true       //エラーダイアログ表示
                        return false;
                    }
                }
            }

            /*◆入力値チェック*/
            const inputTitle = document.getElementById("textTitle").value;
            const inputPass = document.getElementById("textPass").value;
            const inputFile = document.getElementById("file-btn1");
            //---文字数チェック---
            const count = (str) => {
                let len = 0;
                for(let i = 0; i < str.length; i++){
                    (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
                }
                return len;
            }
            //---半角チェック---
            const isHanEisu = (str) => {
                str = (str==null)?"":str;
                if(str.match(/^[A-Za-z0-9]*$/)){
                    return true;
                }else{
                    return false;
                }
            }
            //---画像拡張子チェック---
            const isImageExt = (fileName) => {
                fileName = fileName.substring(fileName.lastIndexOf('.'))
                if (fileName.toUpperCase().match(/\.(jpg)$/i)) {
                    return true
                } if (fileName.toUpperCase().match(/\.(jpeg)$/i)) {
                    return true
                } if (fileName.toUpperCase().match(/\.(png)$/i)) {
                    return true
                }
                return false
            }

            //タイトルが半角50桁を超えている場合
            if(count(inputTitle) > 50){
                self.res = "タイトルは半角50桁以内で入力してください。";
                self.alertErrorVisible = true   //エラーダイアログ表示
                return false;
            }

            //種類が「写真」かつ、選択ファイルに画像以外が存在する場合
            if (self.picToggle == true) {
                for(var k=0; k<inputFile.files.length; k++){
                    if(isImageExt(inputFile.files[k].name)==false){
                        self.res = "選択ファイルに画像以外のファイルが含まれています。";
                        self.alertErrorVisible = true   //エラーダイアログ表示
                        return false;
                    }
                }
            }

            //削除パスワードが半角6桁未満の場合
            //削除パスワードが半角10桁を超えている場合
            //削除パスワードに半角英数以外の文字が含まれている場合
            if(count(inputPass) >= 0 & count(inputPass) < 6 || count(inputPass) > 10 || isHanEisu(inputPass) == false){
                self.res = "削除パスワードは半角英数6～10桁以内で入力してください。";
                self.alertErrorVisible = true   //エラーダイアログ表示
                return false;
            }
            return true;
        },
        SendData: function(){
            let self = this
            this.res = '';
            self.alertLoadingVisible = true;  //投稿中ダイアログを表示

            let inputTitle = document.getElementById("textTitle").value;
            let inputText = document.getElementById("text_area").value;
            let inputPass = document.getElementById("textPass").value;

            //不要文字列を削除
            for(let j=0;j<self.avatar.length;j++){
                self.avatar[j] = self.avatar[j].substring(self.avatar[j].indexOf(',') + 1,self.avatar[j].length)
            }
            self.festival_id = self.$_festivalIDS();

            let params = {
                news_id: self.$_NewsID(),
                title: inputTitle,
                description: inputText,
                password: inputPass,
                contents: self.avatar,
                notification_flg: 0
            }
            let postUpdateNewsAPI = this.$_mixAPI_postUpdateNews(params);
            /*◆投稿処理*/
            postUpdateNewsAPI.then(function(val){
                //投稿成功時
                if(val.result == true){
                    self.alertSuccessVisible = true;
                    //投稿失敗時
                }else{
                    self.res = "投稿に失敗しました。";
                    self.alertErrorVisible = true       //エラーダイアログ表示
                    return;
                }
            }).catch((err)=>{   //通信エラー時
                console.log(err);
                self.res = "投稿に失敗しました。";
                self.alertErrorVisible = true       //エラーダイアログ表示
            }).finally(()=>{
                this.alertVisible = false           //投稿確認ダイアログを非表示
                self.alertLoadingVisible = false;   //投稿中ダイアログを非表示
            });
        },
        checkFile: function(file){
            let result = true
            //キャンセルしたら処理中断
            if (!file) {
                result = false
            }
            return result
        },
        Post() {
            if(this.$_Page() == 'notification'){
                return (
                    this.$_SetmixPC_CurrentPage_Key('notification')
                )
            }
            if(this.$_Page() == 'newsDetail'){
                return (
                    this.$_SetmixPC_CurrentPage_Key('newsDetail')
                )
            }else{
                let self = this   //thenの中からdataを操作するため
                let apiVal = {
                    festival_id:self.$_festivalIDS(),
                    town_id: this.$_AdTownID(),
                    uuid:this.$_uuid()
                }
                let getResultAPI = this.$_mixAPI_postTownData(apiVal)
                getResultAPI.then(function(val){
                    let TownData = {
                        town_id:val[0].town_id,
                        purchase_date:val[0].purchase_date,
                        carpenter:val[0].carpenter,
                        sculpture:val[0].sculpture,
                        remarks:val[0].remarks,
                        storehouse_latitude:val[0].storehouse_latitude,
                        storehouse_longitude:val[0].storehouse_longitude,
                        favorites:val[0].favorites,
                        town_icon:val[0].town_icon,
                        town_img:val[0].town_img,
                        hut_img:val[0].hut_img,
                        Sponsors:val['Sponsors'],
                    }
                    self.$_setTownData(TownData);
                    //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                    self.$_SetmixPC_ActiveTabIndex(4)
                    //タブ用タイトルアイコンデータセット
                    let icon4tab = {
                        Title: val[0].town_name,
                        Icon: val[0].town_icon
                    }
                    self.$_SetmixPC_TitleIcon4Tab(icon4tab);
                    self.$_SetmixPC_CurrentPage_Key('tab')
                }).catch((err)=>{   //通信エラー時
                    console.log(err);
                    self.alertErrorVisible = true   //エラーダイアログ表示
                }).finally(()=>{
                    self.alertLoadingVisible = false    //Loadingダイアログを非表示
                });
            }
        },
    }
}
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .main{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: white;
        opacity: 0.9;
        padding: 3%;
        margin: 2%;
    }
    #textTitle{
        border: 1px solid gray;
    }
    #textPass{
        border: 1px solid gray;
        float:left;
        margin-right:auto;
    }
    .textarea{
        border: 1px solid gray;
    }
    #asta{
        color: red;
    }
    p{
        text-align: left;
        margin: 0;
    }
    #label {
        display: flex;
    }
    .title{
        display: grid;
        grid-template-rows: 1fr 1fr;
        margin-bottom: 3%;
    }
    .right{
        text-align: right;
        margin: 3% 3%;
    }
    .file{
        display: grid;
        grid-template-columns: 1fr 1fr 20%;
    }
    .explanation{
        display: grid;
        grid-template-rows: 1fr 200px;
        margin: 3% 0;
    }
    .checkbox-inline{
        float:left;
        margin-right:auto;
        margin-left:10px;
        margin-top:5px;
    }
    #warning{
        color: red;
    }
    #submit{
        width: 200px;
        background-color: black;
        margin-top: 5%;
    }
    #select{
        background-color: black;
    }
    .img{
        margin-top: 8%;
    }
    #img{
        width: 99%;
    }
</style>
