<template>
  <v-ons-page>
      <div class="background"></div>
      <Toolbar :action="toggleMenu"></Toolbar>
      <BottomToolbar></BottomToolbar>

      <div class="main">
          <div class="rule">
              <h3 id="title"></h3>
              <p id="rule">
                  この利用規約（以下、「本規約」といいます。）は、株式会社テクノアイ（以下、「当社」といいます
                  。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用
                  条件を定めるものです。<BR />
                  登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本
                  規約に従って、本サービスをご利用いただきます。
              </p>
          </div>
          <div class="rule">
              <h3 id="title">第1条（適用）</h3>
              <p id="rule">
                  1. 本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるも
                  のとします。<BR /><BR />
                  2. 当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以
                  下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のい
                  かんに関わらず、本規約の一部を構成するものとします。<BR /><BR />
                  3. 本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定め
                  なき限り、個別規定の規定が優先されるものとします。
              </p>
          </div>
          <div class="rule">
              <h3 id="title">第2条（利用登録）</h3>
              <p id="rule">
                  4. 本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用
                  登録を申請し、当社がこの承認を登録希望者に通知することによって、利用登録が完了す
                  るものとします。<BR /><BR />
                  5. 当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認し
                  ないことがあり、その理由については一切の開示義務を負わないものとします。<BR /><BR />
                  a. 利用登録の申請に際して虚偽の事項を届け出た場合<BR />
                  b. 本規約に違反したことがある者からの申請である場合<BR />
                  c. その他、当社が利用登録を相当でないと判断した場合
              </p>
          </div>
          <div class="rule">
              <h3 id="title">第3条（ユーザーIDおよびパスワードの管理）</h3>
              <p id="rule">
                  6. ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管
                  理するものとします。<BR /><BR />
                  7. ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与
                  し、もしくは第三者と共用することはできません。当社は、ユーザーIDとパスワードの組
                  み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録している
                  ユーザー自身による利用とみなします。<BR /><BR />
                  8. ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、当社
                  に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。
              </p>
          </div>

          <div class="rule">
              <h3 id="title">第4条（禁止事項）</h3>
              <p id="rule">
                  ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。<BR /><BR />
                  9. 法令または公序良俗に違反する行為<BR /><BR />
                  10. 犯罪行為に関連する行為<BR /><BR />
                  11. 当社、本サービスの他のユーザー、または第三者のサーバーまたはネットワークの機能を
                  破壊したり、妨害したりする行為<BR /><BR />
                  12. 当社のサービスの運営を妨害するおそれのある行為<BR /><BR />
                  13. 他のユーザーに関する個人情報等を収集または蓄積する行為<BR /><BR />

                  14. 不正アクセスをし、またはこれを試みる行為<BR /><BR />
                  15. 他のユーザーに成りすます行為<BR /><BR />
                  16. 当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為<BR /><BR />
                  17. 当社、本サービスの他のユーザーまたは第三者の知的財産権、肖像権、プライバシー、名
                  誉その他の権利または利益を侵害する行為<BR /><BR />
                  18. 以下の表現を含み、または含むと当社が判断する内容を本サービス上に投稿し、または送
                  信する行為<BR /><BR />
                  a. 過度に暴力的な表現<BR />
                  b. 露骨な性的表現<BR />
                  c. 人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現<BR />
                  d. 自殺、自傷行為、薬物乱用を誘引または助長する表現<BR />
                  e. その他反社会的な内容を含み他人に不快感を与える表現<BR /><BR />
                  19. 以下を目的とし、または目的とすると当社が判断する行為<BR /><BR />
                  a. 営業、宣伝、広告、勧誘、その他営利を目的とする行為（当社の認めたものを除きま
                  す。）<BR />
                  b. 性行為やわいせつな行為を目的とする行為<BR />
                  c. 面識のない異性との出会いや交際を目的とする行為<BR />
                  d. 他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為<BR />
                  e. 当社、本サービスの他のユーザー、または第三者に不利益、損害または不快感を与え
                  ることを目的とする行為<BR />
                  f. その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為<BR /><BR />
                  20. 宗教活動または宗教団体への勧誘行為<BR /><BR />
                  21. その他、当社が不適切と判断する行為
              </p>
          </div>

          <div class="rule">
              <h3 id="title">第5条（本サービスの提供の停止等）</h3>
              <p id="rule">
                  22. 当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することな
                  く本サービスの全部または一部の提供を停止または中断することができるものとします。<BR /><BR />
                  a. 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合<BR />
                  b. 地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難
                  となった場合<BR />
                  c. コンピュータまたは通信回線等が事故により停止した場合<BR />
                  d. その他、当社が本サービスの提供が困難と判断した場合<BR /><BR />
                  23. 当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいか
                  なる不利益または損害についても、一切の責任を負わないものとします。
              </p>
          </div>

          <div class="rule">
              <h3 id="title">第6条（著作権）</h3>
              <p id="rule">
                  24. ユーザーは、自ら著作権等の必要な知的財産権を有するか、または必要な権利者の許諾を
                  得た文章、画像や映像等の情報に関してのみ、本サービスを利用し、投稿ないしアップロ
                  ードすることができるものとします。<BR /><BR />
                  25. ユーザーが本サービスを利用して投稿ないしアップロードした文章、画像、映像等の著作
                  権については、当該ユーザーその他既存の権利者に留保されるものとします。ただし、当
                  社は、本サービスを利用して投稿ないしアップロードされた文章、画像、映像等について
                  、本サービスの改良、品質の向上、または不備の是正等ならびに本サービスの周知宣伝等
                  に必要な範囲で利用できるものとし、ユーザーは、この利用に関して、著作者人格権を行
                  使しないものとします。<BR /><BR />
                  26. 前項本文の定めるものを除き、本サービスおよび本サービスに関連する一切の情報につい
                  ての著作権およびその他の知的財産権はすべて当社または当社にその利用を許諾した権利
                  者に帰属し、ユーザーは無断で複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可
                  能化を含みます。）、伝送、配布、出版、営業使用等をしてはならないものとします。
              </p>
          </div>

          <div class="rule">
              <h3 id="title">第7条（利用制限および登録抹消）</h3>
              <p id="rule">
                  27. 当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、投稿データを
                  削除し、ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザー
                  としての登録を抹消することができるものとします。<BR /><BR />
                  a. 本規約のいずれかの条項に違反した場合<BR />
                  b. 登録事項に虚偽の事実があることが判明した場合<BR />
                  c. 当社からの連絡に対し、一定期間返答がない場合<BR />
                  d. その他、当社が本サービスの利用を適当でないと判断した場合<BR /><BR />
                  28. 前項各号のいずれかに該当した場合、ユーザーは、当然に当社に対する一切の債務につい
                  て期限の利益を失い、その時点において負担する一切の債務を直ちに一括して弁済しなけ
                  ればなりません。<BR /><BR />
                  29. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責
                  任を負いません。
              </p>
          </div>

          <div class="rule">
              <h3 id="title">第8条（保証の否認および免責事項）</h3>
              <p id="rule">
                  30. 当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有
                  効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害
                  などを含みます。）がないことを明示的にも黙示的にも保証しておりません。<BR /><BR />
                  31. 当社は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負い
                  ません。ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます
                  。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。<BR /><BR />
                  32. 前項ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）によ
                  る債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損
                  害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）
                  について一切の責任を負いません。また、当社の過失（重過失を除きます。）による債務
                  不履行または不法行為によりユーザーに生じた損害の賠償は行いません。<BR /><BR />
                  33. 当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じ
                  た取引、連絡または紛争等について一切責任を負いません。
              </p>
          </div>

          <div class="rule">
              <h3 id="title">第9条（サービス内容の変更等）</h3>
              <p id="rule">
                  当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を
                  中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負
                  いません。
              </p>
          </div>

          <div class="rule">
              <h3 id="title">第10条（利用規約の変更）</h3>
              <p id="rule">
                  当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更するこ
                  とができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当
                  該ユーザーは変更後の規約に同意したものとみなします。
              </p>
          </div>

          <div class="rule">
              <h3 id="title">第11条（個人情報の取扱い）</h3>
              <p id="rule">
                  当社は、本サービスの利用によって取得する個人情報については、当社「プライバシーポリシ
                  ー」に従い適切に取り扱うものとします。
              </p>
          </div>

          <div class="rule">
              <h3 id="title">第12条（権利義務の譲渡の禁止）</h3>
              <p id="rule">
                  ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利
                  もしくは義務を第三者に譲渡し、または担保に供することはできません。
              </p>
          </div>

          <div class="rule">
              <h3 id="title">第13条（準拠法・裁判管轄）</h3>
              <p id="rule">
                  34. 本規約の解釈にあたっては、日本法を準拠法とします。<BR /><BR />
                  35. 本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的
                  合意管轄とします。
              </p>
          </div>
      </div>
      <BR /><BR /><BR />

  </v-ons-page>
</template>

<script>
import Toolbar from "../Common/Toolbar";
import BottomToolbar from "../Common/BottomToolbar";

export default {
  name: "Riyoukiyaku",
  props: ['toggleMenu'],
  components: {
    Toolbar,
    BottomToolbar,
  }
}
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .main{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 5%;
        background-color: white;
        opacity: 0.9;
        border: #b58012 3px solid;
        padding: 20px;
    }
    #title{
        text-align: left;
        font-size: 30px;
    }
    #rule{
        margin: 5% 5%;
        text-align: justify;
    }
</style>
